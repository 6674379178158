import React from "react";
import "../../common-styles/GovernanceDirectorInner.scss";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import Loader from "../../../components/Loader/Loader";
import useGetDirectorInnerData from "../../../hooks/useGetDirectorInnerData";
import parse from "html-react-parser";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";

const GovernanceDirectorInner = () => {
  const { isLoading, directorsInnerData } = useGetDirectorInnerData(
    "/investors/policy/directors/",
    "governance-directors-inner"
  );
   
  if (isLoading) {
    return <Loader />;
  }

  return (
    <main>
    <MetaDecorator metaTitle="Welcome to Raymond | Leaders" />
      <section className="director_inner_sec1" data-aos="fade-in">
        <div className="my_container">
          <BreadCrumb
            linkTwoText="our company"
            linkTwoDisabled
            linkThreeText={directorsInnerData?.investorId?.investorCategory?.title}
            linkThree={`/our-company/${directorsInnerData?.investorId?.investorCategory?.slug}`}
            linkFourText={directorsInnerData?.investorId?.name}
            linkFour={`/our-company/${directorsInnerData?.investorId?.investorCategory?.slug}/${directorsInnerData?.investorId?.slug}`}
            linkFiveText={directorsInnerData?.name}
            linkFiveDisabled
          />
          <h1 className="govover_heading">{directorsInnerData?.name}</h1>

          <div className="govover_desc">
            {directorsInnerData?.description
              ? parse(directorsInnerData?.description)
              : null}
          </div>
        </div>
      </section>
    </main>
  );
};

export default GovernanceDirectorInner;
