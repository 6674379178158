import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

import parse from "html-react-parser";

import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import { request } from "../../utils/axios-utils";
import Loader from "../../components/Loader/Loader";
import { Table, SearchTableInput } from "ant-table-extensions";
import "../common-styles/GovernanceOverview.scss";
import { searchIcon } from "../../images";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import {
  convertSlugtoString,
  getLastSegment,
  investorImageUrl,
} from "../../helpers/utils";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";
import src from "gsap/src";

const fetchOverview = (lastSegment) => {
  return request({ url: `/investors/policy/${lastSegment}`, method: "get" });
};

const InvestorsTable = () => {
  const { width } = useWindowDimensions();
  const [lastSegment, setLastSegment] = useState("");
  const [overviewData, setOverviewData] = useState(null);
  const [columns, setColumns] = useState([]);
  const [datas, setDatas] = useState([]);
  const [searchDataSource, setSearchDataSource] = useState([]);
  const [slugSegmentsArr, setSlugSegmentsArr] = useState([]);

  const { isLoading, refetch: refetchOverview } = useQuery(
    ["investor-table-content", lastSegment],
    () => fetchOverview(lastSegment),
    {
      enabled: false,
      onSuccess: (data) => {
        if (data.data.data) {
          setOverviewData(data.data.data);

          const tempcolumns = data.data.data.templateData.columns.map(
            (col, index) => {

              let object = {
                title: col.label,
                dataIndex: `field${index}`,
                key: `field${index}`,
                
              }
              
              if(index==0){
                object.width="279.34px";
                // object.width=(width> 767) ? 500 : 279;
                object.ellipsis=true;
              } 
              else if (index==1) {
                object.width="330.4px";
              } 
              else if (index==2) {
                object.width="303.37px";
              }
              else if (index==3) {
                object.width="165.2px";
              }
              else if (index==4) {
                object.width="199.24px";
              }
              else if (index==5) {
                object.width="201.24px";
              }
              else if (index==6) {
                object.width="316.38px";
              }
              else if (index==7) {
                object.width="239.29px";
              }
              else if (index==8) {
                object.width="208.25px";
              }
              else if (index==9) {
                object.width="197.24px";
              }
              else if (index==10) {
                object.width="186.28px";
              }
              // else{
              //   object.width="300px";
              // }
              return object;
            }
          );

          setColumns(tempcolumns)
          setDatas(data.data.data.templateData.rows)
          setSearchDataSource(data.data.data.templateData.rows)



        }
      },
    }
  );
  useEffect(() => {
    if (lastSegment) {
      refetchOverview();
    }
  }, [lastSegment]);

  useEffect(() => {
    setSlugSegmentsArr(window.location.pathname.split("/"));
    setLastSegment(getLastSegment(window.location.pathname));
  }, []);

  // const dbColumns = [
  //   {
  //     name: "name",
  //     label: "Name",
  //   },
  //   {
  //     name: "email",
  //     label: "Email",
  //   },
  // ];

  // const columns = dbColumns.map((col, index) => {
  //   return {
  //     title: col.label,
  //     dataIndex: col.name,
  //     key: col.name,
  //   };
  // });
  // const datas = [
  //   {
  //     name: "ansari",
  //     email: "ansari@gmail.com",
  //   },
  //   {
  //     name: "khalid",
  //     email: "khalid@gmail.com",
  //   },
  // ];

  if (isLoading) {
    return <Loader />;
  }
  return (
    <main>
      <MetaDecorator metaTitle="Welcome to Raymond | Investor" />
      {overviewData !== null && (
        <section className="govoversec1" data-aos="fade-in">
          <div className="my_container">
            <BreadCrumb
              linkTwoText="investors"
              linkTwoDisabled
              linkThreeText={convertSlugtoString(slugSegmentsArr[2])}
              linkThreeDisabled
              linkFourText={convertSlugtoString(slugSegmentsArr[3])}
              linkFour={`/investor/${slugSegmentsArr[2]}/${slugSegmentsArr[3]}`}
              linkFiveText={convertSlugtoString(slugSegmentsArr[4])}
              linkFiveDisabled
            />
            <h1 className="govover_heading">{overviewData.title}</h1>
            <p className="govover_subheading">
              {overviewData?.innerDescription
                ? parse(overviewData?.innerDescription)
                : null}
            </p>
            {/* <div className="govover_desc">
              {overviewData?.templateData
                ? parse(overviewData?.templateData?.content)
                : null}
            </div> */}
            <div className="search_table">
              <div className="search_flex">
 <SearchTableInput
              columns={columns}
              dataSource={datas} // 🔴 Original dataSource
              setDataSource={setSearchDataSource} // 🔴 Newly created setSearchDataSource from useState hook
              inputProps={{
                placeholder: "Search investor details",
                // prefix: <SearchOutlined />,
              }}
              fuseProps={{
                threshold: 0.1,
                ignoreLocation:true
                // minMatchCharLength:2
              }}
              // fuzzySearch={true}
            />
              <button type="submit" className="search">
            <img src={searchIcon} className="icon" />
            </button> 
            </div>
            <div className="table-responsive table-bordered">
            <Table
              rowKey={(record) => record._id}
              columns={columns}
              dataSource={searchDataSource}
              // loading={loading}
              // exportableProps={{ showColumnPicker: true }}
              // searchable
              // searchableProps={{
              //   // dataSource,
              //   // setDataSource: setSearchDataSource,
              //   inputProps: {
              //     size:"large",
              //     placeholder: "Search this table...",
              //     // prefix: <SearchOutlined />,
              //   },
              // }}
              pagination={false}
            />
            </div>
            </div>
          </div>
        </section>
      )}
    </main>
  );
};

export default InvestorsTable;
