import React from "react";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { downcomma, reimaginedban, upcomma } from "../../../images";
import "./Reimagined.scss";

const Reimagined = () => {
  const { width } = useWindowDimensions();

  const bannerArr = [
    {
      id: 1,
      img: reimaginedban,
      firsthead: "Our Company | Raymond Re-Imagined",
      desc: "At Raymond Re-imagined, we aspire to collaborate, manufacture superior quality products and inspire the communities at large. Our early adoption of technological innovations to create experiences for stakeholders, employees and customers increase our brand loyalty and satisfaction.",
    },
  ];

  const cmdmsgArr = [
    {
      heading: "CMD’S Message:",
      left: "The pandemic followed by the ongoing war has both been emotional and economic dampeners that have divided the world and contributed to inflationary pressures and rising commodity prices. Imbibing a spirit of resilience, entrepreneurship and agility hence assumes a critical role especially in India when the country is currently the fifth largest contributor to the world market cap following the US, China, Japan and Hong Kong. Despite the headwinds, India is well-poised for steady progress and is increasingly making rapid strides with numerous global names considering our nation as a worthy manufacturing contender as opposed to China. India’s recent entry into Indo-Pacific Economic Framework (IPEF) along with 12 member nations has been a pivotal move pegged to boost our bilateral trade relationships and propel the region as an engine for global economic growth.",
      right:
        "The future lies in imagining the unimaginable! At Re-imagined Raymond, we aspire to achieve just the same. We aim to create not just offerings but an organisation that is built for the future.",
    },
  ];

  const messageArr = [
    {
      id: 1,
      message:
        "<div class='message_heading' ><strong>Focusing On Fundamentals</strong></div><br />FY 21-22 closed on a high note for us with the group recording the highest EBITDA ever and highest Net Profit on a consolidated basis in the last 10 years. Our strategy to focus on the core and recalibrate the fundamental metrics of each business such as revenue, cost and working capital has reaped rich dividends for the Raymond Group. Sustaining our focus on cost optimisation and a significant reduction in our operating costs by 453 Crores as compared to pre-COVID levels of FY19 -20, was critical for our business. The profitability and working capital management have helped in generating free cash flows, thereby reducing our debts, drastically. During the year, there were key affirmative actions taken for our business that has laid a strong foundation in shaping a stronger tomorrow for Raymond.",
    },
    {
      id: 2,
      message:
        "<div class='message_heading' ><strong>New Dimensions Of Retail</strong></div><br />Retailing in the post-pandemic world has thrown open new avenues for consumers to interact and shop. While physical retail will continue to thrive in India, the digital world and social commerce are rapidly surging in India. This is evident by the fact that our revenues from online marketplaces continue to grow steadily and we are currently deploying Artificial Intelligence and Machine Learning capabilities to also create digital fronts for our consumers. Our Brick & Mortar stores are doing well with consumers returning to the stores to shop for the latest styles in office wear or make a big ticket purchase for weddings. Interestingly, we had a record number of weddings in the last quarter of FY21-22 wherein we witnessed a significant increase in our Average Transaction Values. Another new emerging opportunity is the ethnic wear space wherein we are making a rapid foray by opening up new stores for our brand Ethnix by Raymond. Our refreshing new take on ethnic wear crafted from the finest quality fabrics is gaining more popularity as we open new doors to take the brand to newer markets.",
    },
    {
      id: 3,
      message:
        "<div class='message_heading' ><strong>The Towering Heights</strong></div><br />Raymond Realty - our new business has been scaling greater heights of success. Our maiden project – TenX has made steady progress in terms of sales and the first three towers are slated for possession in December 2022, well ahead by two years of RERA timelines. This is rarely witnessed in the real estate sector in India and our fast-paced construction activity is driven by our manufacturing mindset is the key differentiator to achieving this. Owning a home is still a distant dream for millions of Indians and buying real estate at an affordable price point in Mumbai and MMR (Mumbai Metropolitan Region) is becoming increasingly difficult. I feel incredibly proud of my team and of the enormous trust of our buyers which made this dream possible. Our second project – ‘The Address By GS’, a premium township offering mainly 3 and 4 BHKs were launched during the year. In addition to the development of our Thane land, the business is also evaluating numerous options through Joint Development Agreement without land acquisition.",
    },
    {
      id: 4,
      message:
        "<div class='message_heading' ><strong>Re-Org For Revving Up the Future</strong></div><br />The year 2021 was a milestone year that witnessed the approval by the Board of Directors for the subsidisation of Raymond Realty's business into a wholly-owned subsidiary. A new Company by the name of TenX Realty Ltd has been incorporated as a step-down subsidiary of Raymond Ltd to develop real estate business for development /joint development of land and properties in line with its aspiration to expand beyond the Thane region. Additionally, the Board approved the consolidation of the Tools & Hardware and Auto Components Businesses into JK Files. The consolidation of these businesses works in a unified fashion under JK files & Engineering Ltd is showcasing exemplary performance and is likely to derive benefit through synergies. Last but not the least, the consolidation of B2C business by transfer of apparel business from Raymond Apparel Ltd to the parent company Raymond Ltd, which will bring all major apparel brands including Park Avenue, ColorPlus, Parx and Ethnix by Raymond into Raymond Ltd.",
    },
    {
      id: 5,
      message:
        "<div class='message_heading' ><strong>Primed For Growth</strong></div><br />As I conclude, I believe that the contours of the world are altering with the power centre making a gradual shift towards Asia. The upheaval caused by the pandemic has been baffling for many nations. However, India continues to allure with a promising talent pool, strong domestic economy, resilient supply chains and the spirit of Atmanirbharta while spreading its wings to achieve the global dream of being an ideal manufacturing destination. At Raymond, we achieved the preferred supplier status for many more of our global clients and it’s a testimony to the India story that holds the beacon for a brighter future. As we approach our centenary year in 2025, the upcoming years will be defining a century-long journey of Raymond that will be a momentous milestone and a telling tale of how a homegrown brand reinforces its relevance in all Indian hearts.",
    },
  ];

  const bannerList = bannerArr.map((item, i) => (
    <div className="row" key={i}>
      <div className="col-lg-6">
        {width <= 834 ? (
          <div className="text_container">
            <BreadCrumb linkTwoText={item.firsthead} linkTwoDisabled />

            {/* <div className="breadcrumb">{item.firsthead}</div> */}
            <div className="heading">
              Raymond <br />
              Re-Imagined
            </div>
            <div className="desc">{item.desc}</div>
          </div>
        ) : null}
        <img
          src={item.img}
          alt="raymond Brand img"
          className="mainimg"
          loading="lazy"
        />
      </div>
      <div className="col-lg-6 flex_box">
        {width > 834 ? (
          <div className="text_container">
            <BreadCrumb linkTwoText={item.firsthead} linkTwoDisabled />
            {/* <div className="breadcrumb">{item.firsthead}</div> */}
            <div className="heading">
              Raymond <br />
              Re-Imagined
            </div>
            <div className="desc">{item.desc}</div>
          </div>
        ) : null}
      </div>
    </div>
  ));

  const cmdmsgList = cmdmsgArr.map((item, i) => (
    <React.Fragment key={i}>
      <div className="col-lg-6">
        <div className="text_container">
          <div className="heading">{item.heading}</div>
          <p className="desc">{item.left}</p>
        </div>
      </div>
      <div className="col-lg-6 flex_box">
        <div className="text_container">
          {width > 767 ? (
            <img
              src={upcomma}
              alt="comma icon"
              className="comma_icon"
              loading="lazy"
            />
          ) : (
            <div className="mob_icon">
              <img
                src={upcomma}
                alt="comma icon"
                className="comma_icon"
                loading="lazy"
              />
            </div>
          )}

          <div className="quote_msg">
            {item.right}
            <img
              src={downcomma}
              alt="comma icon"
              className="downcomma_icon"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  ));

  const messageList = messageArr.map((item, i) => {
    if (i % 2 === 0) {
      return (
        <div className="col-lg-6 box_padding_right" key={i}>
          <div className="text_container">
            <p
              className="desc"
              dangerouslySetInnerHTML={{ __html: item.message }}
            ></p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="col-lg-6 box_padding_left" key={i}>
          <div className="text_container">
            <p
              className="desc"
              dangerouslySetInnerHTML={{ __html: item.message }}
            ></p>
          </div>
        </div>
      );
    }
  });

  return (
    <>
    <MetaDecorator metaTitle="Welcome to Raymond | Raymond Re-Imagined" />
      <section className="reimagined_sec1" data-aos="fade-in">
        <div className="my_container">{bannerList}</div>
      </section>

      <section className="reimagined_sec2" data-aos="fade-in">
        <div className="my_container">
          <div className="row">{cmdmsgList}</div>
        </div>
      </section>

      <section className="reimagined_sec3" data-aos="fade-in">
        <div className="my_container">
          <div className="row">{messageList}</div>
        </div>
      </section>
    </>
  );
};

export default Reimagined;
