import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./AboutUs.scss";

//swiper imports
import { Navigation, Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

//components
import RightArrowButton from "../../../components/RightArrowButton/RightArrowButton";
import LeftArrowButton from "../../../components/LeftArrowButton/LeftArrowButton";
import InnerPageBanner from "../../../components/InnerPageBanner/InnerPageBanner";

//hooks
import useWindowDimensions from "../../../hooks/useWindowDimensions";

//about us data
import { milestoneData } from "./data";

//images
// import { ReactComponent as OurValCircle } from "../../../images/our-company/about-us/our-val-circle.svg";
import { ReactComponent as OurValCircle1 } from "../../../images/our-company/about-us/valuenew1.svg";
import { ReactComponent as OurValCircle2 } from "../../../images/our-company/about-us/valuenew2.svg";
import { ReactComponent as OurValCircle3 } from "../../../images/our-company/about-us/valuenew3.svg";
import { ReactComponent as Selectdropdownarrow } from "../../../images/our-company/about-us/selectdropdownarrow.svg";
import { milestoneLine } from "../../../images";
import selectdropdownarrow from "../../../images/our-company/about-us/selectdropdownarrow.svg";
import bannerimg from "../../../images/our-company/about-us/new-banner.jpg";
import visionimg from "../../../images/our-company/about-us/visionimg.png";
import missionimg from "../../../images/our-company/about-us/missionimg.png";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";

const AboutUs = () => {
  const { width } = useWindowDimensions();
  const [milestoneIndex, setMilestoneIndex] = useState(0);
  const milestoneTabList = milestoneData.map((tab, i) => (
    <span
      key={i}
      className={`milestone_tab ${milestoneIndex == i ? "active" : ""}`}
      onClick={() => setMilestoneIndex(i)}
    >
      {tab.milestoneYearRange}
    </span>
  ));

  const milestoneSwiperList = milestoneData.map((data, i) => (
    <Swiper
      key={i}
      modules={[Navigation, Pagination, Autoplay]}
      className={`milestone_swiper_container ${
        milestoneIndex == i ? "active" : ""
      }`}
      autoHeight={true} //enable auto height
      loop={true}
      // speed={600}
      // autoplay={{
      //   delay: 3000,
      //   disableOnInteraction: false,
      // }}
      centeredSlides={true}
      // allowTouchMove={false}
      pagination={{
        el: `.milestone_swiper_wrapper .milestone_pagination_${i}`,
        clickable: true,
      }}
      navigation={{
        prevEl: `.milestone_swiper_wrapper .left_btn_${i}`,
        nextEl: `.milestone_swiper_wrapper .right_btn_${i}`,
      }}
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 3,
        },
      }}
      // autoHeight={true}
    >
      {width >= 768 && (
        <img
          src={milestoneLine}
          alt=""
          loading="lazy"
          className="milestone_line"
        />
      )}
      {data.milestoneContent.map((slideData, i) => (
        <SwiperSlide key={i}>
          <div className="milestone_content_box">
            <h4 className="milestone_year">{slideData.milestoneYear}</h4>
            <p className="section_desc milestone_desc">
              {slideData.milestoneYearDesc}
            </p>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  ));

  const mbMilestoneTabList = milestoneData.map((tab, i) => (
    <option value={tab.milestoneYearRange} key={i} data-milestoneindexid={i}>
      {tab.milestoneYearRange}
    </option>
  ));

  const handleMilestoneTabChange = (e) => {
    const milestoneindexid =
      e.target.selectedOptions[0].dataset.milestoneindexid;
    setMilestoneIndex(Number(milestoneindexid));
  };

  return (
    <main>
    <MetaDecorator metaTitle="Welcome to Raymond | About Us" />
      <section className="aboutsec1" data-aos="fade-in">
        <InnerPageBanner
          bannerHeading="About Raymond"
          bannerDesc="Raymond is a diversified group with majority business interests in Textile & Apparel sectors and a presence across varying segments such as Consumer Care, Realty and Engineering in national and international markets."
          bannerImg={bannerimg}
          linkTwoText="our company"
          linkTwoDisabled
          linkThreeText="about us"
          linkThreeDisabled
        />
      </section>

      <section className="aboutsec2" data-aos="fade-in">
        <div className="my_container">
          {/* <h5 className="section_name">Raymond Stories</h5> */}
          <h2 className="section_title">The Journey</h2>
          <p className="section_desc">
            Raymond Limited was first incorporated as the Raymond Woolen Mill in
            1925 near Thane Creek. Lala Kailashpat Singhania took over The
            Raymond Woolen Mill in the year 1944. Ever since then they have been
            analogous with class, elegance and individuality which is evident in
            their men's fashion.
            <br />
            <br />
            In the year 2000, Mr Gautam Singhania was appointed Chairman and
            Managing Director of Raymond Limited. In 2015, Raymond became the
            first textile company in the world to produce Super 250s and
            innovative fabrics. <br />
            <br />
            Raymond with its 97-year-old expertise is a textile powerhouse with
            modern infrastructure and strong fibre-to-fabric manufacturing
            capabilities. Along with being reputed, it is the fastest-growing
            fashion fabric brand. Raymond offers an exquisite range of shirting
            and suiting fabrics across a plethora of options such as Worsted
            fabrics, Cotton, Wool blends, Linen and Denim. The ethnic culture,
            exquisite clothing and the comfort of couture in each of its brands
            - Park Avenue, Raymond Ready To Wear, ColorPlus and Parx Ethnix have
            proven beneficial and created a desirous space in your lives. With
            the central theme of ‘Go Beyond’, the conglomerate has undertaken
            the project to build quality housing for all by introducing Raymond
            Realty and Home.
            <br />
            <br />
            The Raymond Group has a significant hold over the B2B space through
            its garments business. With its presence in this sector and
            state-of-the-art, owned subsidiaries, they manufacture suits,
            trousers, shirts, and jeans for famous fashion brands throughout the
            world.
            <br />
            <br />
            Raymond has remarkable participation in the Denim Market, as one of
            the main producers and preferred suppliers of superior quality Ring
            Denim to some of the world's most prestigious jeanswear companies.
            'Raymond Made To Measure,' which pioneered the original concept of
            bespoke apparel provides a luxurious service to discerning consumers
            who want to customize their appearance. <br />
            <br />
            Raymond Consumer Care Brands- Park Avenue and KamaSutra have a
            prominent presence in the FMCG market. Venturing into the
            engineering business in 1949, JK Files and Engineering Ltd is the
            largest manufacturer of steel files with a global presence in over
            50+ countries. The group has set a strong foot in this space through
            Ring Plus Aqua Ltd (RPAL). Raymond and its brands are also available
            in tier IV and V cities with over 1500 outlets scattered across 600
            towns and a vast network of over 20,000 points of sale in India.
            Raymond is the largest integrated textile company in the world and
            exports its suits to more than 60+ countries including the USA,
            Canada, Europe, Japan and the Middle East. <br />
            <br />
            We go beyond traditional business boundaries because we care. For
            over years now, the Raymond Group has engaged in impactful,
            welfare-driven activities in India like cattle breeding, education,
            tailoring initiative, etc.
            <br />
            <br />
            Driving an amalgamation of high fashion wear at par with the persona
            of the man, that’s Raymond for you! Today, when one thinks of
            Raymond, one thinks of “A Complete Man!”
          </p>
          {/*<p className="section_desc">
             Raymond marked its entry into the Indian market in the year 1925 and
            has set a strong foot in wearable ensembles. Ever since they have
            been analogous with class, elegance and individuality which is
            evident in their men's fashion. <br /> */}
          {/* Since then, they have been akin with class, elegance and
            individuality which is evident in their men's fashion. <br /> */}
          {/* <br /> Raymond with its 97-year-old expertise is a textile
            powerhouse with modern infrastructure and strong fibre-to-fabric
            manufacturing capabilities. Along with being reputed, it is the
            fastest-growing fashion fabric brand. Raymond offers an exquisite
            range of shirting and suiting fabrics across a plethora of options
            such as Worsted fabrics, Cotton, Wool blends, Linen and Denim. The
            ethnic culture, exquisite clothing and the comfort of couture in
            each of its brands - Park Avenue, Raymond Ready To Wear, ColorPlus
            and Parx have proven beneficial and created a desirous space in your
            lives. With the central theme of ‘Go Beyond’, the conglomerate has
            undertaken the project to build quality housing for all by
            introducing Raymond Realty and Home.
            <br /> */}
          {/* <br /> Raymond with 97 year old expertise, is a textile powerhouse
            with modern infrastructure and strong fibre-to-fabric manufacturing
            capabilities is a reputed and fastest-growing fashion fabric brand.
            Raymond offers an exquisite range of shirting and suiting fabrics
            across a plethora of options such as Worsted fabrics, Cotton, Wool
            blends, Linen and Denim. The ethnic culture, exquisite clothing and
            the comfort of couture in each of its brands - Park Avenue, Raymond
            Ready To Wear, ColorPlus and Parx Ethnix have proven beneficial and
            created a desirous space in your lives. With the central theme of
            ‘Go Beyond’, the company has undertaken the project to build quality
            housing for all via Raymond Realty and Home.
            <br />
            <br /> */}
          {/* The Raymond Group has a significant hold over the B2B space through
            its garments business. With its presence in this sector and
            state-of-the-art, owned subsidiaries, they manufacture suits,
            trousers, shirts, and jeans for famous fashion brands throughout the
            world. <br /> */}
          {/* Not barring the B2B space, The Raymond Group has a significant
            presence in this sector through its garments business. With
            state-of-the-art and owned subsidiaries, they manufacture suits,
            trousers, shirts, and jeans for major fashion brands throughout the
            world. <br /> */}
          <br />
          {/* Raymond has remarkable participation in the Denim Market, as one of
            the main producers and preferred suppliers of superior quality Ring
            Denim to some of the world's most prestigious jeanswear companies.
            'Raymond Made To Measure,' which pioneered the original concept of
            bespoke apparel provides a luxurious service to discerning consumers
            who want to customize their appearance. */}
          {/* Raymond is a significant participant in the Denim Market, as one of
            the main producers and preferred suppliers of superior quality Ring
            Denim to some of the world's most prestigious jeanswear companies.
            'Raymond Made To Measure,' which pioneered the original concept of
            bespoke apparel provides a luxurious service to discerning consumers
            who want to tailor their appearance. Raymond Consumer Care Brands-
            Park Avenue and KamaSutra have a prominent presence in the consumer
            care market. Venturing into the engineering business in 1949, JK
            Files and Engineering Ltd is the largest manufacturer of steel files
            with a global presence in over 50+ countries. The group has set a
            strong foot in this space through Ring Plus Aqua Ltd (RPAL). Raymond
            and its brands are also available in tier IV and V cities with over
            1500 outlets scattered across 600 towns and a vast network of over
            20,000 points of sale in India. */}
          <br />
          <br />
          {/* Raymond Consumer Care Brands- Park Avenue and KamaSutra have a
            prominent presence in the Consumer Care market. Venturing into the
            engineering business in 1949, JK Files and Engineering Ltd is the
            largest manufacturer of steel files with a global presence in over
            50+ countries. The group has set a strong foot in this space through
            Ring Plus Aqua Ltd (RPAL). Raymond and its brands are also available
            in tier IV and V cities with over 1500 outlets scattered across 600
            towns and a vast network of over 20,000 points of sale in India. */}
          {/* Driving a melange of avant-grade vogue and persona of the man,
            that’s Raymond for you! Today, when one thinks of Raymond, one
            thinks of “A Complete Man!” */}
          {/* <br />
            <br />
            Driving an amalgamation of high fashion wear at par with the persona
            of the man, that’s Raymond for you! Today, when one thinks of
            Raymond, one thinks of “A Complete Man!”
          </p> */}
        </div>
      </section>

      <section className="aboutsec3" data-aos="fade-in">
        <div className="text_container">
          {/* <h5 className="section_name">Raymond Stories</h5> */}
          <h2 className="section_title">Our Milestones</h2>
        </div>
        {width >= 768 ? (
          <div className="milestone_tabs_flex">{milestoneTabList}</div>
        ) : (
          <div className="mb_milestone_dropdown_wrapper">
            <select
              onChange={handleMilestoneTabChange}
              className="mb_milestone_dropdown"
            >
              {mbMilestoneTabList}
            </select>
            {/* <Selectdropdownarrow className="selectdropdownarrow" /> */}
            <img
              src={selectdropdownarrow}
              alt="select drop down arrow"
              loading="lazy"
              className="selectdropdownarrow"
            />
          </div>
        )}
        <div className="milestone_swiper_wrapper">
          {milestoneSwiperList}
          {milestoneData.map((data, i) => (
            <div
              className={`navigation_wrapper ${
                milestoneIndex == i ? "active" : ""
              }`}
              key={i}
            >
              <div
                className={`milestone_pagination milestone_pagination_${i}`}
              ></div>
              <div className={`left_btn left_btn_${i}`}>
                <LeftArrowButton normalCta ctaText="previous" ctaWhite />
              </div>
              <div className={`right_btn right_btn_${i}`}>
                <RightArrowButton normalCta ctaText="next" ctaWhite />
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="aboutsec4">
        {/* <div className="my_container">
          <div className="row">
            <div className="col-lg-6 desc_col align-self-lg-center">
              <h2 className="section_title">our vision</h2>
              <p className="section_desc vision_desc">
                Incepted in 1925, Raymond has been synonymous with Trust,
                Quality and Excellence. Raymond is a diversified group with
                majority business interests in Textile & Apparel sectors as well
                as presence across diverse segments such as Consumer Care, Realty,
                Engineering and Prophylactics in national and international
                markets. Having enjoyed the patronage of over a billion
                consumers, Raymond is reckoned for delivering world class
                quality products to its consumers since the past nine decades.
                Being a vertically and horizontally integrated manufacturer of
                textiles, Raymond has strong fiber to fabric manufacturing
                capabilities and is a textile po
              </p>
            </div>
            <div className="col-lg-6 text-lg-end" data-aos="fade-left">
              <img
                src={visionimg}
                alt="Raymond vision mission"
                className="visionimg"
                loading="lazy"
                width={754}
                height={704}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 desc_col mission_desc_col order-lg-2 align-self-lg-center">
              <h2 className="section_title">our mission</h2>
              <p className="section_desc">
                Incepted in 1925, Raymond has been synonymous with Trust,
                Quality and Excellence. Raymond is a diversified group with
                majority business interests in Textile & Apparel sectors as well
                as presence across diverse segments such as Cosumer Care, Realty,
                Engineering and Prophylactics in national and international
                markets. Having enjoyed the patronage of over a billion
                consumers, Raymond is reckoned for delivering world class
                quality products to its consumers since the past nine decades.
                Being a vertically and horizontally integrated manufacturer of
                textiles, Raymond has strong fiber to fabric manufacturing
                capabilities and is a textile po
              </p>
            </div>
            <div className="col-lg-6 order-lg-1" data-aos="fade-right">
              <img
                src={missionimg}
                alt="Raymond vision mission"
                className="visionimg"
                loading="lazy"
                width={754}
                height={704}
              />
            </div>
          </div>
        </div> */}
      </section>

      <section className="aboutsec5" data-aos="fade-in">
        <div className="my_container">
          <div className="text_container">
            {/* <h5 className="section_name">Raymond Stories</h5> */}
            <h2 className="section_title">Our values</h2>
          </div>
          <Swiper
            modules={[Navigation]}
            slidesPerView={3}
            spaceBetween={15}
            navigation={{
              prevEl: ".aboutsec5 .left_btn",
              nextEl: ".aboutsec5 .right_btn",
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              767: {
                slidesPerView: 2,
              },
              835: {
                slidesPerView: 3,
              },
            }}
            autoHeight={true}
          >
            <SwiperSlide>
              <div className="ourval_wrapper">
                <OurValCircle1 className="ourValCircle" />
                {/* <h2 className="section_title">Our values</h2> */}
                <p className="desc">
                  The trust bestowed on Raymond by its stakeholders has enabled
                  it to be a market leader. We believe that conducting business
                  in a fair, transparent and ethical manner is pivotal to
                  building strong relationships.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="ourval_wrapper">
                <OurValCircle2 className="ourValCircle" />
                <p className="desc">
                  An iconic brand that has been at the helm of innovation,
                  Raymond has always been recognised for its high-quality
                  product offerings across price points. The testimony to
                  Raymond’s success is its loyal consumer base spanning domestic
                  and international markets.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="ourval_wrapper">
                <OurValCircle3 className="ourValCircle" />
                <p className="desc">
                  At Raymond, we believe in achieving excellence in all we do.
                  Be it crafting world-class offerings, implementing industry
                  best practices or delivering a delightful service experience.
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className="navigation_wrapper">
            <div className="left_btn">
              <LeftArrowButton normalCta ctaText="previous" />
            </div>
            <div className="right_btn">
              <RightArrowButton normalCta ctaText="next" />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AboutUs;
