import React, { useState, useEffect } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import RightArrowButton from "../../components/RightArrowButton/RightArrowButton";
import { ToastContainer, toast } from "react-toastify";
import * as yup from "yup";
import { mergeAll } from "ramda";
import axios from "axios";
import {
  callicon,
  contactban,
  locationicon,
  mailicon,
  contactpic,
  contactbanmob,
  dropddownicon,
  ctaRightArrowBlack,
  ctaRightArrowRed,
} from "../../images";
import "./contactus.scss";

import http from "../../helpers/http";
import { cleanPhoneCode } from "../../helpers/utils";
import config from "../../config/config";
import Joi from "joi";

import { useNavigate } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const ContactUs = () => {
  const { width } = useWindowDimensions();
  const [mouseEnter, setMouseEnter] = useState(false);

  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [codes, setCodes] = useState([]);
  const [countryCode, setcountryCode] = useState("+91");

  let base_url = config.api_url + "/forms/contact";

  const navigate = useNavigate();

  const schema = Joi.object({
    name: Joi.string().trim().required().label("Name"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    phone: Joi.number().required().label("Phone"),

    detail: Joi.string().trim().required().label("Detail"),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    

    e.preventDefault();
    
    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    const { data } = await http.post(base_url, {
      ...values,
      phone: `${countryCode}${values.phone}`,
    });

    if (data) {
      // redirect
      navigate("/thank-you");
      // props.history.push("/thank-you");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const fetchCodes = async () => {
    const { data } = await http.get(
      `${config.api_url}/general/countries/codes`
    );
   
    if (data) {
      setCodes(data);
    }
  };

  useEffect(() => {
    fetchCodes();
  }, []);

  return (
    <>
      <MetaDecorator metaTitle="Welcome to Raymond | Contact Us" />
      <section className="contact_sec1" data-aos="fade-in">
        <div className="my_container">
          <div className="text_container">
            <BreadCrumb linkTwoText="Contact Us" linkTwoDisabled />
            <h1 className="heading">Contact Us</h1>
            <p className="desc">
              Whenever you need us, we're here to assist you.
            </p>
          </div>
          <img
            src={contactban}
            alt="raymond contact img"
            className="mainimg"
            loading="lazy"
            width={1631}
            height={509}
          />
        </div>
      </section>

      <section className="contact_sec2" data-aos="fade-in">
        <div className="my_container">
          <div className="text_container">
            <h4 className="breadcrumb">Connect With Us</h4>
            {width > 600 ? (
              <p className="texttoright">
                Whether you’ve got a query, need assistance or have some
                feedback, <br />
                we are ready to answer any and every question of yours.
              </p>
            ) : null}
            <h2 className="heading">Here’s Where You Can Find Us</h2>
            {width <= 600 ? (
              <p className="texttoright">
                Whether you’ve got a query, need assistance or have some
                feedback, we are ready to answer any and every question of
                yours.
              </p>
            ) : null}
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="box_container">
                <img className="icon_img" src={locationicon} />
                <h4 className="heading">Registered Address</h4>
                <p className="desc">
                  Plot No. 156 / H. No. 2, Village Zadgaon, Ratnagiri – 415 612,
                  Maharashtra.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="box_container">
                <img className="icon_img" src={callicon} />
                <h4 className="heading">Call Us On</h4>
                <p className="desc">
                  <a className="number" href="tel:1800 222 001">
                    1800 222 001
                    <br /> <strong>Days of operation</strong>- Monday - Saturday{" "}
                    <br />
                    <strong>Hours of operation</strong>- 9:30 AM - 6:30 PM
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="box_container">
                <img className="icon_img" src={mailicon} />
                <h4 className="heading">Write To Us On</h4>
                <p className="desc">
                  <a
                    className="number"
                    href="mailto:consumercarecell@raymond.in"
                  >
                    consumercarecell@raymond.in
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact_sec3" data-aos="fade-in">
        {width > 767 ? (
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3773.930927448838!2d72.83913631437477!3d18.934451261327997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7d1d068a09dff%3A0x660e7aa6b8ed61a5!2sThe%20Raymond%20Shop!5e0!3m2!1sen!2sin!4v1648734754382!5m2!1sen!2sin"
            width="100%"
            height="672"
            style={{ border: "0", margin: "0", padding: "0" }}
            loading="lazy"
          ></iframe>
        ) : (
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3773.930927448838!2d72.83913631437477!3d18.934451261327997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7d1d068a09dff%3A0x660e7aa6b8ed61a5!2sThe%20Raymond%20Shop!5e0!3m2!1sen!2sin!4v1648734754382!5m2!1sen!2sin"
            width="100%"
            height="363"
            style={{ border: "0", margin: "0", padding: "0" }}
            loading="lazy"
          ></iframe>
        )}
      </section>

      <section className="contact_sec4" data-aos="fade-in">
        <div className="my_container">
          <div className="row">
            <div className="col-lg-7">
              <div className="text_container">
                <div className="breadcrumb">Inquire Now</div>
                <h4 className="heading">Get In Touch</h4>
                <p className="desc">
                  We’d love to help you. Please get in touch with our Expert
                  Support Team by selecting a category related to your enquiry.
                </p>
              </div>
              <div className="form_container">
                <form className="form-group" onSubmit={handleSubmit}>
                  <div className="material-textfield">
                    <input
                      placeholder=" "
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                    <label>Name*</label>
                  </div>
                  <div className="error">{formError.name}</div>
                  {/* Changes done */}
                  <div className="contact_email">
                    <div className="material-textfield contactus">
                      <div className="mobile_no_flex">
                        <div className="cc_box">
                          <select
                            className="cc_dd"
                            name="countryCode"
                            id="countryCode"
                            value={countryCode}
                            onChange={(e) => setcountryCode(e.target.value)}
                          >
                            {codes.map((code, i) => {
                              return (
                                <option
                                  value={`+${cleanPhoneCode(code)}`}
                                  key={i}
                                >
                                  {`+${cleanPhoneCode(code)}`}
                                </option>
                              );
                            })}
                          </select>
                          <img
                            className="dropwdown_icon"
                            width={9}
                            height={8}
                            loading="lazy"
                            src={dropddownicon}
                          />
                        </div>
                        <div className="no_box">
                          <input
                            placeholder=" "
                            type="tel"
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                          />
                          <label>Phone Number*</label>
                        </div>
                      </div>
                      <div className="error">{formError.phone}</div>
                    </div>
                    <div className="material-textfield emailid">
                      <input
                        placeholder=" "
                        type="text"
                        role="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      <label>E-mail ID*</label>
                      <div className="error">{formError.email}</div>
                    </div>
                  </div>
                  {/* <div className="material-textfield">
                    <select
                      className="input select_padding"
                      name="category"
                      value={values.category}
                      onChange={handleChange}
                    >
                      <option value="">Select Category*</option>
                      <option value="demo1" className="demo1 active">
                        Demo1
                      </option>
                      <option value="demo2">Demo2</option>
                      <option value="demo3">Demo3</option>
                    </select>
                    <div className="error">{formError.category}</div>
                  </div> */}
                  <div className="material-textfield">
                    <input
                      placeholder=" "
                      type="text"
                      name="detail"
                      value={values.detail}
                      onChange={handleChange}
                    />
                    <label>Enquiry Details*</label>
                    <div className="error">{formError.detail}</div>
                  </div>
                  <div className="cta_wrapper">
                    <button type="submit">
                      <span
                        className="right_arrow_cta_flex apply_hover"
                        onMouseEnter={() => setMouseEnter(true)}
                        onMouseLeave={() => setMouseEnter(false)}
                      >
                        <span className="cta_text">Submit</span>

                        <img
                          src={
                            mouseEnter ? ctaRightArrowBlack : ctaRightArrowRed
                          }
                          alt="Button arrow design"
                          className="cta_right_arrow"
                        />
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="zoomout">
                <img className="contact_img" src={contactpic} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default ContactUs;
