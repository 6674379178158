import React from "react";
import "./thankyou.scss";
import RightArrowButton from "../../components/RightArrowButton/RightArrowButton";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { thankicon, thankiconmob } from "../../images";
import { homepageurl } from "../../helpers/constant-words";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const ThankYou = () => {
  const { width } = useWindowDimensions();
  return (
    <>
    <MetaDecorator metaTitle="Welcome to Raymond | Thank You" />
      <section className="thank_sec1">
        <div className="my_container">
          <div className="flex_box">
            {width > 834 ? (
              <img
                src={thankicon}
                alt="thankyou img"
                className="thank_img"
                loading="lazy"
                width="100%"
                height="100%"
              />
            ) : (
              <img
                src={thankiconmob}
                alt="thankyou img"
                className="thank_img"
                loading="lazy"
                width="100%"
                height="100%"
              />
            )}
            <div className="subtitle">Thank you</div>
            <p className="desc">
              Your message has been received,
              <br />
              we will update you shortly.
            </p>
            <div className="cta_wrapper">
              <RightArrowButton
                linkTo={homepageurl}
                ctaText="BACK TO HOME"
                ctaWhite
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ThankYou;
