import React, { useRef, useState } from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import Counteritem from "../../../components/Counteritem/Counteritem";
import {
  dropdownarrow,
  awardsaccoladsbanner,
  leftarrow,
  rightarrow,
  awardDesktop,
  awardMobile,
} from "../../../images";
import "./awards&accolads.scss";
import useGetAwardsData from "../../../hooks/useGetAwardsData";
import Loader from "../../../components/Loader/Loader";
import { awardsImageUrl } from "../../../helpers/utils";
import parse from "html-react-parser";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";

const bannerArr = [
  {
    id: 1,
    img: awardsaccoladsbanner,
    firsthead: "Our Company | Awards & Accolades",
    heading: "Awards & Accolades",
    desc: "Our unwavering endeavour of setting new standards and benchmarks time and again, delivering excellence to our customers, the quality of our workplace culture and environmental consciousness has won us numerous prestigious awards and accolades.",
  },
];

// const aboutusArr = [
//   {
//     id: 1,
//     img: lifeabout,
//     firsthead: "",
//     heading: "About Us",
//     desc: "Established nine decades ago, Raymond has been synonymous with complete men’s fashion ever since.",
//   },
// ];

// const raymondgroupArr = [
//   {
//     id: 1,
//     firsthead: "",
//     heading: "About Raymond",
//     desc: "Raymond is a major suiting brand in India, noted for its uncompromising quality, design sophistication and commanding presence. Raymond has maintained its brand reputation over the years by evoking a sense of effortless strength and regality.",
//     desc2:
//       "Exclusive Quality, Constant Innovation and Proactive Solutions along with industrious effort is a daily ritual at Raymond. Using cutting-edge technology that offers stringent start-to-finish quality control at every step of the product manufacturing cycle to create extraordinary fabrics with unrivalled efficiency. Building an organization that is built for the future, we aim to adapt to bespoke technological innovations to create experiences for stakeholders, employees and customers to increase our brand loyalty and satisfaction.",
//   },
// ];

// const awardArr = [
//   {
//     tabName: "Textile",
//     list: [
//       {
//         desc: "hello",
//       },
//     ],
//   },
//   {
//     tabName: "Apparel",
//     list: [
//       {
//         desc: "hello",
//       },
//     ],
//   },
//   {
//     tabName: "Retail",
//     list: [
//       {
//         desc: "hello",
//       },
//     ],
//   },
//   {
//     tabName: "Personal Care",
//     list: [
//       {
//         desc: "hello",
//       },
//     ],
//   },
//   {
//     tabName: "Internal Business",
//     list: [
//       {
//         desc: "hello",
//       },
//     ],
//   },
//   {
//     tabName: "HR",
//     list: [
//       {
//         desc: "hello",
//       },
//     ],
//   },
//   {
//     tabName: "Realty Business",
//     list: [
//       {
//         desc: "hello",
//       },
//     ],
//   },
// ];

// const awardsArr = [
//   {
//     id: 1,
//     img: awardimg,
//     heading: "Textile",
//     list: {
//       desc: "Raymond is a diversified group with majority Brand Raymond has pushed the benchmarks of quality, leadership and extraordinary product across fashion. leadership and extraordinary product across fashion",
//     },
//   },
//   {
//     id: 1,
//     img: awardimg,
//     heading: "Retail",
//     list: {
//       desc: "Raymond is a diversified group with majority Brand Raymond has pushed the benchmarks of quality, leadership and extraordinary product across fashion. leadership and extraordinary product across fashion",
//     },
//   },
// ];

const AwardsAccolads = () => {
  const { width } = useWindowDimensions();
  const [awardIndex, setAwardIndex] = useState(0);
  const { isLoading, awardsData } = useGetAwardsData();
  const tabRef = useRef();

  const leftClickHandler = (scrollOffset, i) => {
    tabRef.current.scrollLeft += scrollOffset;
    if (i !== 0) {
      setAwardIndex(i - 1);
    } else {
      setAwardIndex(i);
    }
  };

  const rightClickHandler = (scrollOffset, i) => {
    tabRef.current.scrollLeft += scrollOffset;
    if (i !== awardsData?.data?.data?.length - 1) {
      setAwardIndex(i + 1);
    } else {
      setAwardIndex(i);
    }
  };
  const bannerList = bannerArr.map((item, i) => (
    <div className="row" key={i}>
      <div className="col-lg-6 flex_box">
        {width > 834 ? (
          <div className="text_container">
            <BreadCrumb linkTwoText={item.firsthead} linkTwoDisabled />
            {/* <div className="breadcrumb">{item.firsthead}</div> */}
            <div className="heading">{item.heading}</div>
            <div className="desc">{item.desc}</div>
          </div>
        ) : null}
      </div>
      <div className="col-lg-6">
        {width <= 834 ? (
          <div className="text_container">
            <BreadCrumb linkTwoText={item.firsthead} linkTwoDisabled />
            <div className="heading">{item.heading}</div>
            <div className="desc">{item.desc}</div>
          </div>
        ) : null}
        {/* <div className="counter_wrapper_flex">
          <div className="counter_cont">
            <Counteritem
              startCount={450}
              endCount={600}
              duration={2}
              countTitle="Towns And Cities With A Pan India Network"
              plus
              // aboveThousand
            />
          </div>
          <div className="counter_cont">
            <Counteritem
              startCount={1200}
              endCount={1400}
              duration={2}
              countTitle="Retail Stores (2.3 Mn sq. ft) With Approximately 80% Franchisee"
              plus
            />
          </div>
          <div className="counter_cont">
            <Counteritem
              startCount={19500}
              endCount={20000}
              duration={2}
              countTitle="Branded Textile Point Of Sale"
              plus
            />
          </div>
          <div className="counter_cont">
            <Counteritem
              startCount={7500}
              endCount={8000}
              duration={2}
              countTitle="Branded Apparel Point Of Sale"
              plus
            />
          </div>
        </div> */}
        {/* <div className="counter_wrapper_flex">
          <div className="counter_img_wrapper"> */}
          
          {width > 767 ? (
            <>
              <img
                src={awardDesktop}
                alt="Awards & Accolades"
                className="award_img"
                loading="lazy"
              />
            </>
          ) : (
            <>
              <img
                src={awardMobile}
                alt="Awards & Accolades"
                className="award_img"
                loading="lazy"
              />
            </>
          )}
      </div>
    </div>
  ));

  // const aboutusList = aboutusArr.map((item, i) => (
  //   <React.Fragment key={i}>
  //     <div className="col-lg-6 flex_box2 order-lg-2">
  //       <div className="text_container">
  //         <div className="breadcrumb">{item.firsthead}</div>
  //         <div className="heading">{item.heading}</div>
  //         <div className="desc">{item.desc}</div>
  //       </div>
  //       <div className="counter_wrapper_flex">
  //         <div className="counter_cont">
  //           <Counteritem
  //             startCount={450}
  //             endCount={600}
  //             duration={2}
  //             countTitle="Towns And Cities With A Pan India Network"
  //             plus
  //             // aboveThousand
  //           />
  //         </div>
  //         <div className="counter_cont">
  //           <Counteritem
  //             startCount={1200}
  //             endCount={1400}
  //             duration={3}
  //             countTitle="Retail Stores (2.3 Mn sq. ft) With Approximately 80% Franchisee"
  //             plus
  //           />
  //         </div>
  //         <div className="counter_cont">
  //           <Counteritem
  //             startCount={19500}
  //             endCount={20000}
  //             duration={5}
  //             countTitle="Branded Textile Point Of Sale"
  //             plus
  //           />
  //         </div>
  //         <div className="counter_cont">
  //           <Counteritem
  //             startCount={7500}
  //             endCount={8000}
  //             duration={4}
  //             countTitle="Branded Apparel Point Of Sale"
  //             plus
  //           />
  //         </div>
  //       </div>
  //     </div>
  //     <div className="col-lg-6 flex_box order-lg-1">
  //       <div className="zoomout">
  //         <img
  //           src={lifeabout}
  //           alt="about us image"
  //           className="about_img"
  //           loading="lazy"
  //           width="100%"
  //           height="100%"
  //         />
  //       </div>
  //     </div>
  //   </React.Fragment>
  // ));

  // const raymondgroupList = raymondgroupArr.map((item, i) => (
  //   <div className="text_container" key={i}>
  //     <div className="firsdesc">{item.firsthead}</div>
  //     <div className="heading">{item.heading}</div>
  //     <div className="desc">{item.desc}</div>
  //     <div className="desc2">{item.desc2}</div>
  //   </div>
  // ));

  const awardTabList = awardsData?.data?.data?.map(({ name }, i) => (
    <span
      key={i}
      onClick={() => setAwardIndex(i)}
      className={`award_tab ${parseInt(awardIndex) == i ? "active" : ""} `}
    >
      {name}
    </span>
  ));

  const mbAwardTabList = awardsData?.data?.data?.map(({ name }, i) => (
    <option value="Textile" key={i} data-awardindexid={i}>
      {name}
    </option>
  ));

  const awardsList = awardsData?.data?.data?.map(
    ({ title, description, image }, i) => (
      <div
        className={`row awards_detail ${
          parseInt(awardIndex) === i ? "active" : ""
        }`}
        key={i}
      >
        <div className="col-lg-6 order-lg-2">
          <div className="zoomout">
            <img
              src={awardsImageUrl + image}
              alt="raymond Brand img"
              className="mainimg"
              loading="lazy"
            />
          </div>
        </div>
        <div className="col-lg-6 order-lg-1 flex_box3">
          <div className="text_container">
            <div className="heading">{title}</div>
            <div className="desc">{parse(description)}</div>
          </div>
        </div>
      </div>
    )
  );

  const handleAwardTabChange = (e) => {
    const awardIndexId = e.target.selectedOptions[0].dataset.awardindexid;
    setAwardIndex(Number(awardIndexId));
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <MetaDecorator metaTitle="Welcome to Raymond | Awards and Accolades" />
      <section className="life_sec1" data-aos="fade-in">
        <div className="my_container">{bannerList}</div>
      </section>
      {/* <section className="life_sec2" data-aos="fade-in">
        <div className="my_container">
          <div className="row">{aboutusList}</div>
        </div>
      </section> */}
      {/* <section className="life_sec3" data-aos="fade-in">
        <div className="my_container">{raymondgroupList}</div>
      </section> */}
      <section className="life_sec4 extra_space_issue" data-aos="fade-in">
        <div className="my_container">
          <div className="text_container">
            {/* <div className="firsdesc">Raymond Stories</div> */}
            {/* <div className="heading">Awards</div> */}
            {width > 767 ? (
              <>
                <img
                  src={leftarrow}
                  onClick={() => leftClickHandler(-100, awardIndex)}
                  className="left-arrow"
                  alt="left arrow"
                />
                <div ref={tabRef} className="awards_tabs_wrapper">
                  {awardTabList}
                </div>
                <img
                  src={rightarrow}
                  onClick={() => rightClickHandler(+100, awardIndex)}
                  className="right-arrow"
                  alt="right arrow"
                />
              </>
            ) : (
              <div className="award_tabs_wrapper">
                <div className="mb_award_tabs_dropdown_wrapper">
                  <select
                    onChange={handleAwardTabChange}
                    className="mb_awards_dropdown"
                  >
                    {mbAwardTabList}
                  </select>
                  <img
                    src={dropdownarrow}
                    alt="raymond dropdown logo"
                    className="downdropdownarrow"
                  />
                </div>
              </div>
            )}
            {awardsList}
          </div>
        </div>
      </section>
    </>
  );
};

export default AwardsAccolads;
