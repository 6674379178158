import { useQuery } from "react-query";
import { request } from "../utils/axios-utils";

const fetchMediaReports = () => {
  return request({ url: "/media?limit=0&month=all", method: "get" });
};
const fetchGovernace = () => {
  return request({ url: "/our-company/governance", method: "get" });
};
export const useGetMediaRoutes = () => {
  const { isLoading: isMediaRoutesLoading, data: mediaReportsData } = useQuery(
    "media-routes",
    fetchMediaReports,
    {
      select: (data) => {
        return data.data.data;
      },
    }
  );

  return {
    isMediaRoutesLoading,
    mediaReportsData,
  };
};
export const useGetGovernanceRoutes = () => {
  const { isLoading: isGovernaceRoutesLoading, data: governaceData } = useQuery(
    "governace-routes",
    fetchGovernace,
    {
      select: (data) => {
       
        return data.data.data.policies;
      },
    }
  );

  return {
    isGovernaceRoutesLoading,
    governaceData,
  };
};
export const useGetGovernanceCategoryRoutes = () => {
  const { isLoading: isGovernaceCategoryRoutesLoading, data: governaceCategoryData } = useQuery(
    "governace-category-routes",
    fetchGovernace,
    {
      select: (data) => {
       
        return data?.data?.data?.subcategories;
      },
    }
  );

  return {
    isGovernaceCategoryRoutesLoading,
    governaceCategoryData,
  };
};
