import React from "react";
import "./notfound.scss";
import RightArrowButton from "../../components/RightArrowButton/RightArrowButton";
import { homepageurl } from "../../helpers/constant-words";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const NotFound = () => {
  return (
    <>
    <MetaDecorator metaTitle="Welcome to Raymond | Not Found" />
      <section className="notfound_sec1">
        <div className="my_container">
          <div className="row">
            <div className="col-lg-5 ">
              <div className="flex_box">
                <div className="title">404</div>
                <div className="subtitle">PAGE NOT FOUND</div>
                <p className="desc">
                  Sorry, the page you're looking for doesn't exist.
                </p>
                <div className="cta_wrapper">
                  <RightArrowButton
                    linkTo={homepageurl}
                    ctaText="BACK TO HOME"
                    ctaWhite
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-7"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFound;
