export const milestoneData = [
  {
    milestoneYearRange: "1925 - 1999",
    milestoneContent: [
      {
        milestoneYear: 1925,
        milestoneYearDesc:
          "A small woollen mill named Raymond Woolen Mill was set up in Thane.",
      },
      {
        milestoneYear: 1944,
        milestoneYearDesc:
          "Lala Kailashpat Singhania purchased Raymond. The mill was producing coarse woollen blankets and small quantities of low-cost woollen garments. Soon after, Raymond began a steady process of adopting new technological advancements, which resulted in the manufacturing of superior quality fabrics.",
      },
      {
        milestoneYear: 1950,
        milestoneYearDesc:
          "In the post-independence desire for self-sufficiency, production began at the company's new manufacturing unit JK Files. The unit created native engineering files.",
      },
      {
        milestoneYear: 1952,
        milestoneYearDesc:
          "Gopalkrishna Singhania arrived to assist his uncle Lala Kailashpat Singhania at the Raymond Mills in Mumbai.",
      },
      {
        milestoneYear: 1958,
        milestoneYearDesc:
          "Raymond became the first company to blend polyester with wool and introduced Terool — a runaway success that was the forerunner in the series of superfine fabric blends that was launched later.",
      },
      {
        milestoneYear: 1958,
        milestoneYearDesc:
          "Raymond forayed into the retail sector by opening the first exclusive retail showroom in King’s Corner – Ballard Estate in Mumbai.",
      },
      {
        milestoneYear: 1960,
        milestoneYearDesc:
          "1960s earmarked total replacement of machinery with sophisticated machinery and since then modernization has become a way of life at Raymond. Raymond was the first in the country to import the most modern machinery which was ever available in the world at that time.",
      },
      {
        milestoneYear: 1967,
        milestoneYearDesc:
          "The company launched Trovine — a first-of-its-kind cool and comfortable fabric ideal for Indian summers.",
      },
      {
        milestoneYear: 1968,
        milestoneYearDesc: "Set up a readymade garments plant in Thane.",
      },
      {
        milestoneYear: 1979,
        milestoneYearDesc:
          "A new manufacturing facility was set up at Jalgaon to meet the increasing demand for worsted woollen fabrics.",
      },
      {
        milestoneYear: 1980,
        milestoneYearDesc:
          "Dr Vijaypat Singhania, the son of Lala Kailashpat Singhania took over the reins as the Chairman of Raymond. An AMP Alumnus from Harvard and a high flier in spirit and deed, it was under his leadership that the group forayed into several new areas such as polyester filament yarn, indigo denim, cold-rolled steel, prophylactics and charter air services.",
      },
      {
        milestoneYear: 1984,
        milestoneYearDesc:
          "A new plant was set up that enabled Raymond to upgrade the quality of its traditional product line of woollen blankets and produce a superior variety of shawls and blankets. Raymond was the pioneer in the development of machine washable wool and wool blended suiting with a plethora of finishes to offer total easy-care and stain-proof fabric.",
      },
      {
        milestoneYear: 1986,
        milestoneYearDesc:
          "Park Avenue was launched to provide stylish and innovative wardrobe solutions to well-dressed gentlemen. As India's premium lifestyle brand, its designs embody the latest in international fabric, styling, colour and fashion trends.",
      },
      {
        milestoneYear: 1990,
        milestoneYearDesc:
          "Mr Gautam Singhania was appointed Chairman and Managing Director of Raymond Limited.",
      },
      {
        milestoneYear: 1990,
        milestoneYearDesc: "Raymond's first overseas showroom in Oman.",
      },
      {
        milestoneYear: 1991,
        milestoneYearDesc:
          "The company launched KamaSutra, India’s premium condom brand. Within a year of the launch, KamaSutra became the second-largest condom-selling brand in India.",
      },
      {
        milestoneYear: 1995,
        milestoneYearDesc:
          "The company introduced a range of superfine pure wool collections under the lineage line (Super 100S to Super 140S).",
      },
      {
        milestoneYear: 1996,
        milestoneYearDesc:
          "The group forayed into the aviation sector with the launch of Raymond Aviation. Raymond Aviation provides air charter services to corporate travellers in India.",
      },
      {
        milestoneYear: 1996,
        milestoneYearDesc:
          "The company also forayed into manufacturing Denim, focusing on quality, innovation and the creation of exclusive products & designs that have always caught the eye of some of the world's leading jeanswear brands as well as making it amongst the top producers of speciality ring denim in India with a 60% market share.",
      },
      {
        milestoneYear: 1999,
        milestoneYearDesc:
          "Parx a premium casual wear brand was launched to offer customers a range of semi-formal and casual clothes.",
      },
    ],
  },
  {
    milestoneYearRange: "2000 - 2010",
    milestoneContent: [
      // {
      //   milestoneYear: 2000,
      //   milestoneYearDesc:
      //     "Mr Gautam Singhania was appointed Chairman and Managing Director of Raymond Limited.",
      // },
      {
        milestoneYear: 2002,
        milestoneYearDesc: "This year witnessed the acquisition of ColorPlus.",
      },
      {
        milestoneYear: 2003,
        milestoneYearDesc:
          "Silver Spark Apparel Limited was set up for manufacturing suits and formal trousers catering largely to export markets.",
      },
      {
        milestoneYear: 2005,
        milestoneYearDesc:
          "Raymond achieved a rare feat and a historical milestone with the creation of the world's finest worsted-suiting fabrics from the finest wool ever produced in the world- The Super 230s made up of 11.8 microns of wool.",
      },
      {
        milestoneYear: 2006,
        milestoneYearDesc:
          "A Greenfield unit was set up in Kolhapur to produce high-value cotton shirting.",
      },
      {
        milestoneYear: 2006,
        milestoneYearDesc:
          "World’s largest integrated composite Textile mill was set up in Vapi to produce the finest quality worsted fabric.",
      },
      {
        milestoneYear: 2008,
        milestoneYearDesc:
          "The launch of Readymade garments under the Raymond brand name now known as Raymond Ready to Wear.",
      },
    ],
  },
  {
    milestoneYearRange: "2010 - 2017",
    milestoneContent: [
      {
        milestoneYear: 2011,
        milestoneYearDesc:
          "Raymond is the first textile company in the world to produce the Super 250s fabric and the first in India to produce innovative fabrics from bamboo fibre, stain-resistant and UV-resistant fabrics and many such innovations.",
      },
      {
        milestoneYear: 2014,
        milestoneYearDesc: "Opened the first EBO for Raymond Ready to Wear.",
      },
      {
        milestoneYear: 2016,
        milestoneYearDesc:
          "Launched Technosmart- Smartest fabric in the World.",
      },
      {
        milestoneYear: 2016,
        milestoneYearDesc:
          "Setting up a new regional office in Dubai, Middle East.",
      },
      {
        milestoneYear: 2016,
        milestoneYearDesc:
          "Laid the Foundation Stone for setting up a greenfield Textile Manufacturing Plant in Amravati.",
      },
      {
        milestoneYear: 2016,
        milestoneYearDesc:
          "Construction begins for a greenfield manufacturing/garments facility in Ethiopia.",
      },
      {
        milestoneYear: 2017,
        milestoneYearDesc:
          "Garmenting Plant commissioned at Hawassa in Ethiopia.",
      },
      {
        milestoneYear: 2017,
        milestoneYearDesc: "A new office was established in New York, USA.",
      },
    ],
  },
  {
    milestoneYearRange: "2018 - 2020",
    milestoneContent: [
      {
        milestoneYear: 2018,
        milestoneYearDesc:
          "Launch of Raymond Custom Tailoring to create a strong ecosystem of one lakh tailors by 2020.",
      },
      {
        milestoneYear: 2018,
        milestoneYearDesc:
          "Raymond crossed the landmark of 900 stores across 500+ towns and cities in India, making this the fastest ever retail expansion by any brand.",
      },
      {
        milestoneYear: 2019,
        milestoneYearDesc:
          "Raymond’s foray into the real estate sector with its maiden venture of building a 3000(approx.)-unit Aspirational Housing in Thane.",
      },
      {
        milestoneYear: 2019,
        milestoneYearDesc:
          "Consolidation of Consumer Care businesses into Raymond Consumer Care.",
      },
      {
        milestoneYear: 2019,
        milestoneYearDesc:
          "Inauguration of Smt. Sunitidevi Singhania School, Thane to carry forward the legacy of high-quality education.",
      },
      {
        milestoneYear: 2020,
        milestoneYearDesc:
          "Launched Virasafe – a high-performance anti-viral fabric in India. During the COVID 19 pandemic Raymond repurposed its garment factories to craft PPE offerings.",
      },
    ],
  },
];
