export const applyScroll = (selectedEle, yOffsetVal) => {
  const yOffset = yOffsetVal;

  const element = document.getElementById(selectedEle);
  if (element) {
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  }
};

export const reduceDescSize = (desc, length = 80) => {
  let reducedDesc;
  if (desc.length <= length) {
    reducedDesc = desc;
    return reducedDesc;
  } else {
    reducedDesc = desc.substring(0, length);
    reducedDesc = reducedDesc.substr(
      0,
      Math.min(reducedDesc.length, reducedDesc.lastIndexOf(" "))
    );
    // reducedDesc += "...";
    return reducedDesc;
  }
};
export const convertSlugtoString = (slug) => {
  const slugStr = String(slug);
  const regex = /-/g;
  return slugStr.replace(regex, " ");
};
export const getLastSegment = (pathname) => {
  if (pathname) {
    let slashIndex = pathname.lastIndexOf("/") + 1;
    let urlLastSegment = pathname.substring(slashIndex, pathname.length);
    return urlLastSegment;
  }
};


export const cleanPhoneCode = (code) => {
 return code.replace(
    /[^a-zA-Z0-9 ]/g,
    ""
  )
};



export const imageUrl = process.env.REACT_APP_IMAGE_URL;

export const susBlocksImageUrl = imageUrl + "/sustainability/";
export const businessImageUrl = imageUrl + "/business/";
export const brandImageUrl = imageUrl + "/brand/";
export const interviewImageUrl = imageUrl + "/interview/";
export const mediaImageUrl = imageUrl + "/media/";
export const investorImageUrl = imageUrl + "/investor/";
export const awardsImageUrl = imageUrl + "/award/";
export const sliderImageUrl = imageUrl + "/slider/";
