import React from "react";
import "./MediaContacts.scss";
import { Fade } from "react-awesome-reveal";
// import Banner from "../../../components/";
// import Banner from "../../components/Banner/Banner";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import {
  callicon,
  locationicon,
  mailicon,
  mediabannerimg,
} from "../../../images";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";
const MediaContacts = () => {
  const { width } = useWindowDimensions();
  return (
    <>
      <MetaDecorator metaTitle="Welcome to Raymond | Media Contact" />
      <section className="mediacontact_sec1" data-aos="fade-in">
        <div className="my_container">
          <div className="text_container">
            <BreadCrumb
              linkTwoText="Newsroom"
              linkTwoDisabled
              linkThreeText="Media Contact"
              linkThreeDisabled
            />
            <h1 className="heading">Media Contact</h1>
            <p className="desc">
              Are you a journalist or a media professional? Do you need to
              connect with a member of our media relations team? Find our
              contact details down below.
            </p>
          </div>
          <img
            src={mediabannerimg}
            alt="raymond trust img"
            className="mainimg"
            width={1631}
            height={509}
          />
        </div>
      </section>

      <section
        className="mediacontact_sec2 extra_space_issue"
        data-aos="fade-up"
      >
        <div className="my_container">
          <div className="text_container">
            <h4 className="breadcrumb">Connect With Us</h4>
            {width > 600 ? (
              <p className="texttoright">
                Please use the following Raymond media relations contacts only{" "}
                <br />
                for inquiries from the media.
              </p>
            ) : null}
            <h2 className="heading">Connect For Media Enquiries</h2>
            {width <= 600 ? (
              <p className="texttoright">
                Please use the following Raymond media relations contacts only
                for inquiries from the media.
              </p>
            ) : null}
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="box_container">
                <img
                  className="icon_img"
                  alt="location img"
                  src={locationicon}
                />
                <h4 className="heading">Raymond Limited</h4>
                <p className="desc">
                  Jekegram, Pokhran Road No 1, <br /> Thane - 400 606,
                  Maharashtra, India.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="box_container">
                <img className="icon_img" alt="call img" src={callicon} />
                <h4 className="heading">Rohit Khanna (Media Queries)</h4>
                {/* <p className="desc">
                  <a className="number" href="tel:+9">
                  
                  </a>
                  <br />
                  <a className="number" href="tel:+91">
                  
                  </a>
                </p> */}
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="box_container">
                <img className="icon_img" alt="mail img" src={mailicon} />
                <h4 className="heading">Write To Us On</h4>
                <p className="desc">
                  <a className="number" href="mailto:rohit.khanna@raymond.in">
                    rohit.khanna@raymond.in
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MediaContacts;
