import React, { useEffect, useState } from "react";
import "./investor.scss";

import { mediabannerimg, 
investorban,
stockup, 
stockdown, 
investorrelation 
} from "../../images";
import investorGraph from "../../images/homepage/investor-graph.png";

import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
// import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import axios from "axios";
import RightArrowButton from "../../components/RightArrowButton/RightArrowButton";
import config from "../../config/config";
import http from "../../helpers/http";
import { investorURL } from "../../helpers/constant-words";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

// import helper from "../../helpers/helper";
const bannerArr = [
  {
    id: 1,
    img: investorban,
    firsthead: "HOME | INVESTORS",
    heading: "Investors",
    desc: "Raymond is a diversified group with majority business interests in Textile & Apparel sectors as well as presence across diverse segments such as FMCG, Realty, Engineering and Prophylactics in national and international markets.",
  },
];

const Investor = () => {
  const { width } = useWindowDimensions();

  const [stock, setStock] = useState({});
  const [datas, setDatas] = useState([]);

  const base_url = config.api_url + "/investors"; //without trailing slash
  const image_url = config.image_url + "/investor/"; //with trailing slash

  const bannerList = bannerArr.map((item, i) => (
    <div className="row" key={i}>
      <div className="col-lg-8">
        {width <= 834 ? (
          <div className="text_container">
            <BreadCrumb linkTwoText={item.heading} linkTwoDisabled />
            <div className="heading">{item.heading}</div>
            <div className="desc">{item.desc}</div>
          </div>
        ) : null}
        <img src={item.img} alt="raymond business img" className="mainimg" />
      </div>
      <div className="col-lg-4 flex_box">
        {width > 834 ? (
          <div className="text_container">
            <BreadCrumb linkTwoText={item.heading} linkTwoDisabled />
            <div className="heading">{item.heading}</div>
            <div className="desc">{item.desc}</div>
          </div>
        ) : null}
      </div>
    </div>
  ));

  const investorList = datas.map((item, i) => {
    return (
      <div className="col-lg-6 col2" key={i}>
        <div className="investor_box_1">
          <div className="investor_main_1">
            <img
              src={image_url + item.image}
              className="investor_main_img_1"
              alt={item.name}
            />
          </div>
          <h1 className="investor_main_head_1">{item.name}</h1>
          <div className="btn_wrapper">
            <RightArrowButton
              ctaText="Know More"
              linkTo={`${investorURL}/${item.slug}`}
            />
          </div>
        </div>
      </div>
    );
  });

  const fetchDatas = async () => {
    const { data } = await http.get(base_url);

    if (data) {
      setDatas(data);
    }
  };

  const fetchStock = async () => {
    try {
      const {
        data: { data },
      } = await axios({
        method: "GET",
        url: "https://priceapi.moneycontrol.com/pricefeed/bse/equitycash/R",
      });
      setStock(data);
      // setIsLoader(false);
    } catch (err) {
      console.log("stock error:-", err);
      setStock({});
      // setIsLoader(false);
    }
  };

  useEffect(() => {
    fetchDatas();
    fetchStock();
  }, []);

  return (
    <>
      <MetaDecorator
        metaTitle="Welcome to Raymond | Investor"
        metaDesc="Raymond is a diversified group with majority business interests in Textile & Apparel sectors as well as presence across diverse segments such as FMCG, Realty, Engineering and Prophylactics in national and international markets."
      />
      <section className="investor_sec1" data-aos="fade-in">
        <div className="my_container">{bannerList}</div>
      </section>

      <section className="investor_sec2" data-aos="fade-up">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col2 order-md-2">
              <h5 className="section_name">INVESTORS</h5>
              <h2 className="section_title">Investor Relations</h2>
              <p className="section_desc">
                Being a vertically and horizontally integrated manufacturer of
                textiles, Raymond has strong fibre to fabric manufacturing
                capabilities and is a textile powerhouse with state of the art
                modern infrastructure. Raymond offers an exquisite range of
                shirting and suiting fabrics across a plethora of options such
                as Worsted fabrics, Cotton, Wool blends, Linen and Denim. With
                over 1500 stores spread across 600 towns and an expansive
                network of over 20,000 points-of-sale in India, Raymond and its
                brands are also available in tier IV & V cities. Over the years,
                the brand has emerged as a preferred choice for top design
                houses globally. Raymond has also been a leading player in High
                value cotton shirting fabrics and is the No.1 brand in the OTC
                space.
              </p>
            </div>
            <div className="col-md-7 col1 order-md-1 stock_wrapper">
              <img
                src={investorGraph}
                alt="Investor relations graph"
                className="investordummyimg"
              />
              <div className="stock_price">
                ₹ {Math.round(stock.pricecurrent)}{" "}
                <span>
                  {Math.sign(parseInt(stock.pricepercentchange)) > 0 ? (
                    <img
                      className="updown_arrow"
                      alt="down arrow stock"
                      src={stockup}
                    />
                  ) : (
                    <img
                      className="updown_arrow"
                      alt="down arrow stock"
                      src={stockdown}
                    />
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="common_banner_content_wrapper_1">
        <div className="my_container">
          <div className="row investor_column_2">
            {investorList}
            <div className="col-lg-6 col2">
              <div className="investor_box_1">
                <div className="investor_main_1">
                  <img
                    src={investorrelation}
                    className="investor_main_img_1"
                    alt="investor contact details"
                  />
                </div>
                <h1 className="investor_main_head_1">
                  Investor Relations Contact
                </h1>

                <div className="btn_wrapper">
                  <RightArrowButton ctaText="Know More" linkTo="/investor-contact" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Investor;
