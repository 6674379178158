import React, { useState } from "react";
import { Link } from "react-router-dom";
// import { useParams } from "react-router-dom";
import { getLastSegment } from "../../helpers/utils";

const SubNavItem = ({
  dropDownData,
  dropDownIndex,
  handleSubIndex,
  subIndex,
}) => {
  return (
    <>
      <div className="wrapper_for_subnav_div">
        <h5
          className={`dropdown_heading ${
            subIndex == dropDownIndex ? "active" : ""
          }`}
          onClick={() => handleSubIndex(dropDownIndex)}
        >
          {dropDownData.dropDownLink ? (
            <Link
              to={dropDownData.dropDownLink}
              className="investor_contact_text"
            >
              {dropDownData.dropDowntitle}
            </Link>
          ) : (
            dropDownData.dropDowntitle
          )}
          {dropDownData.dropDownLink ? null : (
            <span className="dropdown_heading_plus_minus">
              {subIndex == dropDownIndex ? "-" : "+"}
            </span>
          )}
        </h5>
        <div
          className={` wrapper_for_subnav ${
            subIndex == dropDownIndex ? "active" : ""
          }`}
        >
          <div
            className={`dropdown_list ${
              subIndex == dropDownIndex ? "active" : ""
            }`}
          >
            {dropDownData.linkArr.map((linkData, i) => (
              <div className="dropdown_list_item" key={i}>
                {/* <NavLink className="dropdown_list_item_link" to={linkData.navLink}>
              {linkData.navTitle.toLowerCase()}
            </NavLink> */}
               
                
                <a
                  href={linkData.navLink}
                  className={`dropdown_list_item_link ${
                    getLastSegment(linkData.navLink).toLowerCase() ===
                    getLastSegment(window.location.pathname).toLowerCase()
                      ? "active"
                      : null
                  } `}
                >
                  {linkData.navTitle}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubNavItem;
