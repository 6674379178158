import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { request } from "../utils/axios-utils";

const fetchDirectorsInner = (lastSegment, apiPath) => {
  return request({ url: `${apiPath}${lastSegment}`, method: "get" });
};

const useGetDirectorInnerData = (apiPath, queryKeyName) => {
  const { boardOfDirectorsInnerPage } = useParams();
  const [directorsInnerData, setDirectorsInnerData] = useState(null);

  const { isLoading, refetch: refetchDirectorsInner } = useQuery(
    [queryKeyName, boardOfDirectorsInnerPage],
    () => fetchDirectorsInner(boardOfDirectorsInnerPage, apiPath),
    {
      // cacheTime: 0,
      enabled: false,
      onSuccess: (data) => {
        if (data.data.data) {
          setDirectorsInnerData(data.data.data);
        }
      },
    }
  );
  useEffect(() => {
    refetchDirectorsInner();
  }, []);

  return { isLoading, directorsInnerData };
};

export default useGetDirectorInnerData;
