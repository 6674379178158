import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

import "../../common-styles/GovernanceComplianceReport.scss";
import { useQuery } from "react-query";
import { request } from "../../../utils/axios-utils";
import { getLastSegment, mediaImageUrl } from "../../../helpers/utils";
import Loader from "../../../components/Loader/Loader";
import moment from "moment";

import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";

import { ReactComponent as ReportDownload } from "../../../images/general/report-download.svg";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";

const fetchComplianeReports = (lastSegment) => {
  return request({ url: `/media/${lastSegment}`, method: "get" });
};

const MediaReportsComplianceReport = () => {
  const [lastSegment, setLastSegment] = useState("");

  const [reportsData, setReportsData] = useState(null);

  const { isLoading, refetch: refetchComplianeReports } = useQuery(
    ["media-compliance-reports", lastSegment],
    () => fetchComplianeReports(lastSegment),
    {
      enabled: false,
      onSuccess: (data) => {
        if (data.data.data) {
          setReportsData(data.data.data);
        }
      },
    }
  );

  useEffect(() => {
    if (lastSegment) {
      refetchComplianeReports();
    }
  }, [lastSegment]);

  useEffect(() => {
    setLastSegment(getLastSegment(window.location.pathname));
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <main>
    <MetaDecorator metaTitle="Welcome to Raymond | Media" />
      {reportsData && (
        <section className="compsec1" data-aos="fade-in">
          <div className="my_container">
            <BreadCrumb
              linkTwoText="newsroom"
              linkTwoDisabled
              linkThreeText="news and media"
              linkThree="/newsroom/news-and-media"
              linkFourText={reportsData.name}
              linkFourDisabled
            />
            <h1 className="govover_heading">{reportsData.title}</h1>
            <p className="govover_subheading">
              {reportsData?.innerDescription
                ? parse(reportsData?.innerDescription)
                : null}
            </p>
            <div className="report_table_wrapper">
              <table className="report_table">
                <tbody>
                  <tr className="report_table_row">
                    <th className="report_title_col report_title_heading_col">
                      report
                    </th>
                    {/* <th className="date_col date_heading_col">date</th> */}
                    <th className="download_logo_wrapper download_logo_heading_wrapper">
                      file
                    </th>
                  </tr>
                  {reportsData.templateData.map((report, i) => (
                    <tr className="report_table_row" key={i}>
                      <td className="report_title_col">
                        {report.title}
                        {/* <br />{" "}
                        <span className="mb_show_date">
                          {moment(report.date).format("MMMM, YYYY")}
                        </span> */}
                      </td>
                      {/* <td className="date_col">
                        {moment(report.date).format("MMMM, YYYY")}
                      </td> */}
                      <td className="download_logo_wrapper">
                        <a href={mediaImageUrl + report.file} target="_blank">
                          <ReportDownload className="report_download_logo" />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      )}
    </main>
  );
};

export default MediaReportsComplianceReport;
