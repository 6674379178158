import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./homepage.scss";
import FsLightbox from "fslightbox-react";
//swiper imports
import { Navigation, Autoplay, Pagination, FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

//components
import RightArrowButton from "../../components/RightArrowButton/RightArrowButton";
import LeftArrowButton from "../../components/LeftArrowButton/LeftArrowButton";
import Loader from "../../components/Loader/Loader";

//hooks
import useWindowDimensions from "../../hooks/useWindowDimensions";

//images import
import { ReactComponent as Fbgrey } from "../../images/homepage/fb-grey.svg";
import { ReactComponent as Instagrey } from "../../images/homepage/insta-grey.svg";
import { ReactComponent as Linkgrey } from "../../images/homepage/link-grey.svg";
import { ReactComponent as Twiitergrey } from "../../images/homepage/twiiter-grey.svg";
import { ReactComponent as Youtubegrey } from "../../images/homepage/youtube-grey.svg";
import pintrst from "../../images/homepage/pintrst.svg";
// import { ReactComponent as Pintrstgrey } from "../../images/homepage/pintrst.svg";
import { ReactComponent as HoverRightArrow } from "../../images/homepage/hover-right-arrow.svg";
// import bannerimg from "../../images/homepage/bannerimg.png";
import aboutSmallimg from "../../images/homepage/about-smallimg.png";
import playicon from "../../images/homepage/playicon.svg";
import directorimage from "../../images/homepage/directorimage.jpg";
// import buisnessimg1 from "../../images/homepage/buisnessimg1.png";
// import buisnessimg2 from "../../images/homepage/buisnessimg2.png";
// import brandimg1 from "../../images/homepage/brandimg1.png";
// import brandimg2 from "../../images/homepage/brandimg2.png";
// import newsimg1 from "../../images/homepage/newsimg1.png";
import aboutusbelowbanner from "../../images/homepage/aboutusbelowbanner.jpg";
import investorGraph from "../../images/homepage/investor-graph.png";
import instaimg1 from "../../images/homepage/instaimg1.png";
import instaimg2 from "../../images/homepage/instaimg2.png";
import {
  quoteComma,
  selectdropdownarrow,
  stockdown,
  stockup,
} from "../../images";
import {
  useGetMediasData,
  useGetOurBrandsData,
  useGetOurBusinessData,
  useGetSliderData,
  useGetSusData,
} from "../../hooks/useGetHomepageData";
import {
  businessImageUrl,
  sliderImageUrl,
  brandImageUrl,
  susBlocksImageUrl,
} from "../../helpers/utils";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import axios from "axios";
import config from "../../config/config";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";
// import { Pagination } from "@mui/material";

gsap.registerPlugin(ScrollTrigger);
const ourBusinessBreakpoints = {
  0: {
    slidesPerView: 1.05,
  },
  345: {
    slidesPerView: 1.2,
  },
  767: {
    slidesPerView: 2.6,
  },
  1193: {
    slidesPerView: 3.8,
  },
  1280: {
    slidesPerView: 4.1,
  },
  1500: {
    slidesPerView: 4,
  },
  1541: {
    slidesPerView: 3.6,
  },
  1800: {
    slidesPerView: 4,
  },
  1921: {
    slidesPerView: 4.1,
  },
};
const sustainabilityBreakpoints = {
  0: {
    slidesPerView: 1.3,
    spaceBetween: 20,
  },
  767: {
    slidesPerView: 1.7,
    spaceBetween: 0,
  },
  992: {
    slidesPerView: 2.2,
  },
  1050: {
    slidesPerView: 2.7,
  },
  1540: {
    slidesPerView: 2.5,
  },
  1930: {
    slidesPerView: 2.9,
  },
};

// const bannerImagesArr = [bannerimg, bannerimg, bannerimg];

// const ourBusinessData = [
//   {
//     img: buisnessimg1,
//     title: "UCO Denim",
//   },
//   {
//     img: buisnessimg2,
//     title: "Realty",
//   },
//   {
//     img: buisnessimg1,
//     title: "UCO Denim",
//   },
//   {
//     img: buisnessimg2,
//     title: "Lifestyle",
//   },
//   {
//     img: buisnessimg1,
//     title: "Condumer Care",
//   },
//   {
//     img: buisnessimg2,
//     title: "Realty",
//   },
//   {
//     img: buisnessimg1,
//     title: "UCO Denim",
//   },
//   {
//     img: buisnessimg2,
//     title: "Realty",
//   },
// ];

// const ourBrandsData = [
//   {
//     tabName: "Raymond",
//     slideList: [
//       {
//         img: brandimg1,
//       },
//       {
//         img: brandimg2,
//       },
//       {
//         img: brandimg1,
//       },
//       {
//         img: "https://dummyimage.com/366x361/54was2",
//       },
//     ],
//   },
//   {
//     tabName: "Raymond Apparel",
//     slideList: [
//       {
//         img: brandimg1,
//       },
//       {
//         img: brandimg1,
//       },
//       {
//         img: brandimg2,
//       },
//     ],
//   },
//   {
//     tabName: "Consumer Care",
//     slideList: [
//       {
//         img: brandimg1,
//       },
//       {
//         img: brandimg2,
//       },
//       {
//         img: brandimg1,
//       },
//     ],
//   },
//   {
//     tabName: "Raymond Realty",
//     slideList: [
//       {
//         img: brandimg2,
//       },
//       {
//         img: brandimg1,
//       },
//       {
//         img: brandimg2,
//       },
//       {
//         img: brandimg1,
//       },
//     ],
//   },
// ];

// const newsAndMediaData = [
//   {
//     img: newsimg1,
//     title: "Raymond fine fabric",
//     desc: "Being a vertically and horizontally integrated manufacturer of",
//   },
//   {
//     img: newsimg1,
//     title: "Raymond fine fabric",
//     desc: "Being a vertically and horizontally integrated manufacturer of",
//   },
//   {
//     img: newsimg1,
//     title: "Raymond fine fabric",
//     desc: "Being a vertically and horizontally integrated manufacturer of",
//   },
// ];

// const susData = [
//   {
//     img: buisnessimg1,
//     title: "Content 01",
//   },
//   {
//     img: buisnessimg2,
//     title: "Content 02",
//   },
//   {
//     img: buisnessimg1,
//     title: "Content 03",
//   },
// ];

const Homepage = () => {
  const { width } = useWindowDimensions();
  const bannerEl = useRef();
  // const q = gsap.utils.selector(bannerEl);
  const [ourBrandIndex, setOurBrandIndex] = useState(0);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [vidLink, setVidLink] = useState("");
  const [stock, setStock] = useState({});
  const { isSliderLoading, sliderData } = useGetSliderData();
  const { isOurBusinessLoading, businessData } = useGetOurBusinessData();
  const { isOurBrandsLoading, brandsData } = useGetOurBrandsData();
  const { isMediaLoading, mediaData } = useGetMediasData();
  const { isSusLoading, susDataArr } = useGetSusData();

  const [instapost, setinstapost] = useState([]);
  let insta_api = config.insta_api;
  let insta_token = config.insta_token;
  let insta_limit = 12;
  let insta_fields =
    "id,media_type,media_url,username,timestamp,caption,permalink,thumbnail_url";

  const brandsDataArr = brandsData?.data?.data;
 

  const handleImageWrapperClick = (videoLink) => {
    setIsVideoModalOpen(!isVideoModalOpen);
    setVidLink(videoLink);
  };

  const fetchStock = async () => {
    try {
      const {
        data: { data },
      } = await axios({
        method: "GET",
        url: "https://priceapi.moneycontrol.com/pricefeed/bse/equitycash/R",
      });
      setStock(data);
      // setIsLoader(false);
    } catch (err) {
      console.log("stock error:-", err);
      setStock({});
      // setIsLoader(false);
    }
  };

  useEffect(() => {
    fetchStock();
  }, []);

  useLayoutEffect(() => {
    // const el = document.querySelectorAll(".inner_span");
    // const spanArr = Array.from(el);
    // if (spanArr.length > 0) {
    //   const animName = "letterAnim";
    //   let animDelay = 0;
    //   spanArr.forEach((spanEl) => {
    //     spanEl.style.animation = `${animName} 0.5s linear ${animDelay}s forwards`;
    //     animDelay += 0.1;
    //   });
    // }
    // if (width > 1279) {
    // gsap.to(q(".banner_slides_wrapper"), {
    //   // scaleX: 1,
    //   width: "100%",
    //   scrollTrigger: {
    //     trigger: q(".banner_slides_wrapper"),
    //     start: "top 40%",
    //     end: "top 10%",
    //     scrub: true,
    //   },
    // });
    // }
  }, [
    isSliderLoading,
    isOurBusinessLoading,
    isOurBrandsLoading,
    isMediaLoading,
    isSusLoading,
  ]);

  const fetchInstaPost = async () => {
    try {
      const response = await axios.get(insta_api, {
        params: {
          fields: insta_fields,
          access_token: insta_token,
          limit: insta_limit,
        },
      });

      const { data } = response;

      if (data) {
        setinstapost(data.data);
      }
    } catch (error) {
      console.log("insta error", error.response);
    }
  };

  useEffect(() => {
    fetchInstaPost();
  }, []);

  useEffect(() => {}, [brandsDataArr]);
  const instapostlist = instapost.length
    ? instapost.map((data, key) => {
        if (data.media_type == "IMAGE") {
          return (
            // <figure className="swiper-slide" key={key}>
            //   <a href={data.permalink} target="_blank">
            //     <img
            //       src={
            //         data.media_type == "VIDEO"
            //           ? data.thumbnail_url
            //           : data.media_url
            //       }
            //       alt="Laxmi Instagram media"
            //       loading="lazy"
            //       className="instaimg"
            //     />
            //   </a>
            // </figure>

            <SwiperSlide>
              <a href={data.permalink} target="_blank">
                <img
                  src={
                    data.media_type == "VIDEO"
                      ? data.thumbnail_url
                      : data.media_url
                  }
                  alt={data.caption}
                  className="instaimg"
                  loading="lazy"
                  width={450}
                  height={450}
                />
              </a>
            </SwiperSlide>
          );
        }
      })
    : null;

  //logical functions
  const handleBrandTabChange = (e) => {
    const brandIndexId = e.target.selectedOptions[0].dataset.brandindexid;
    setOurBrandIndex(brandIndexId);
  };

  // dom appending functions
  const bannerImgList = sliderData?.data?.data?.map(
    ({ image, type, thumbnail, url }, i) => (
      <SwiperSlide key={i}>
        {type === "image" ? (
          <img
            src={sliderImageUrl + image}
            alt="Banner"
            className="bannerimg"
            width={1631}
            height={670}
          />
        ) : (
          <figure
            className="bannerimg_wrapper"
            onClick={() => handleImageWrapperClick(url)}
          >
            <img
              src={sliderImageUrl + thumbnail}
              alt="Banner"
              className="bannerimg"
              width={1631}
              height={670}
            />
            <img src={playicon} alt="play icon" className="playicon" />
          </figure>
        )}
      </SwiperSlide>
    )
  );

  const ourBusinessList = businessData?.data?.data?.map(
    ({ title, image, _id, website }, i) => (
      <SwiperSlide key={i}>
        <Link to={website} state={{ boxId: _id }}>
          <div className="business_box">
            <img
              src={businessImageUrl + image}
              alt="Our business"
              loading="lazy"
              width={425}
              height={415}
              className="businessimg"
            />
            <HoverRightArrow className="circle_right_arrow" />
            <span className="business_text">{title}</span>
          </div>
        </Link>
      </SwiperSlide>
    )
  );

  const ourBrandTabList = brandsDataArr?.map(({ title }, i) => (
    <span
      key={i}
      onClick={() => setOurBrandIndex(i)}
      className={`brand_tab ${parseInt(ourBrandIndex) === i ? "active" : ""}`}
    >
      {title}
    </span>
  ));

  const mbOurBrandTabList = brandsDataArr?.map(({ title }, i) => (
    <option value={title} key={i} data-brandindexid={i}>
      {title}
    </option>
  ));
  const ourBrandsSwiperList = brandsDataArr?.map((brandData, i) => (
    <Swiper
      className={`our_brand_swiper ${
        parseInt(ourBrandIndex) === i ? "active" : ""
      }`}
      key={i}
      // loop={brandData.brands.length > 3 ? true : false}
      modules={[Navigation, Autoplay]}
      navigation={{
        prevEl: `.our_brands_swiper_wrapper .left_btn_${i}`,
        nextEl: `.our_brands_swiper_wrapper .navigation_wrapper_next_${i}`,
      }}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        330: {
          slidesPerView: 1.3,
          spaceBetween: 0,
        },
        768: {
          slidesPerView: 2.1,
        },
        992: {
          slidesPerView: 2.5,
        },
        1194: {
          slidesPerView: 3,
        },
        1921: {
          slidesPerView: 3.4,
          spaceBetween: 0,
        },
      }}
    >
      {brandData.brands.map(({ image, _id }, i) => (
        <SwiperSlide key={i}>
          <Link to={`/our-brand/${brandData.slug}`} state={{ boxId: _id }}>
            <div className="brand_box">
              <img
                src={brandImageUrl + image}
                alt="Brand img"
                className="brandimg"
                loading="lazy"
                width={366}
                height={361}
              />
              <HoverRightArrow className="brand_hover_right_arrow" />
            </div>
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  ));

  const newsAndMediaList = mediaData?.map(
    ({ bgImage, name, description, slug, template, url }, i) => (
      <SwiperSlide key={i}>
        {template !== "external" ? (
          <Link to={`/newsroom/news-and-media/${slug}`}>
            <div className="news_box">
              <img
                src={bgImage}
                alt="Our news"
                loading="lazy"
                width={413}
                height={384}
                className="newsimg"
              />
              <div className="text_container">
                <HoverRightArrow className="circle_right_arrow" />
                <h5 className="new_box_title">{name.toLowerCase()}</h5>
                <p className="desc">{description}</p>
              </div>
            </div>
          </Link>
        ) : (
          <a href={url} target="_blank" rel="noreferrer">
            <div className="news_box">
              <img
                src={bgImage}
                alt="Our news"
                loading="lazy"
                width={413}
                height={384}
                className="newsimg"
              />
              <div className="text_container">
                <HoverRightArrow className="circle_right_arrow" />
                <h5 className="new_box_title">{name.toLowerCase()}</h5>
                <p className="desc">{description}</p>
              </div>
            </div>
          </a>
        )}
      </SwiperSlide>
    )
  );

  const susList = susDataArr?.map(({ image, title, slug }, i) => (
    <SwiperSlide key={i}>
      <Link to={`/beyond-business/csr/${slug}`}>
        <div className="sus_box">
          <img
            src={susBlocksImageUrl + image}
            alt="Sustainability"
            loading="lazy"
            width={384}
            height={379}
            className="susimg"
          />
          <HoverRightArrow className="circle_right_arrow" />
          <h5 className="sus_box_title">{title.toLowerCase()}</h5>
        </div>
      </Link>
    </SwiperSlide>
  ));

  if (
    isSliderLoading ||
    isOurBusinessLoading ||
    isOurBrandsLoading ||
    isMediaLoading ||
    isSusLoading
  ) {
    return <Loader />;
  }

  return (
    <>
      <MetaDecorator metaTitle="Welcome to Raymond | Home" />
      <section className="homesec1" ref={bannerEl}>
        <div className="my_container">
          <div className="row">
            <div className="col-lg-12 col1">
              <h1 className="banner_heading">
                <span className="outer_span">
                  <span className="inner_span inner_span1">Trust,</span>
                </span>{" "}
                <span className="outer_span">
                  <span className="inner_span inner_span2 redbg">Quality,</span>
                </span>{" "}
                <br />{" "}
                <span className="outer_span">
                  <span className="inner_span inner_span3">Excellence.</span>
                </span>{" "}
              </h1>
              <p className="banner_desc_style" data-aos="fade-up">
                Raymond is a diversified group with a significant presence in
                the Textile & Apparel sector and diverse segments such as
                Consumer Care, Realty, and Engineering in national and
                international markets.
              </p>
            </div>
            
          </div>
        </div>

        {width >= 600 ? 
        <div className="socialmedia_float_icon">
              <ul className="media_icons_wrapper custom-li">
                <li>
                  <a
                    href="https://in.linkedin.com/company/raymond-limited"
                    target="_blank"
                    rel="noreferrer"
                    className="social_icon_wrapper"
                  >
                    <Linkgrey className="banner_social_icons" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/raymond_the_complete_man/?hl=en"
                    target="_blank"
                    rel="noreferrer"
                    className="social_icon_wrapper"
                  >
                    <Instagrey className="banner_social_icons" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/RaymondLimited/"
                    target="_blank"
                    rel="noreferrer"
                    className="social_icon_wrapper"
                  >
                    <Fbgrey className="banner_social_icons" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/theraymondltd?lang=en"
                    target="_blank"
                    rel="noreferrer"
                    className="social_icon_wrapper"
                  >
                    <Twiitergrey className="banner_social_icons" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/user/raymondlimited"
                    target="_blank"
                    rel="noreferrer"
                    className="social_icon_wrapper"
                  >
                    <Youtubegrey className="banner_social_icons" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://in.pinterest.com/Raymond_corp/?eq=raymond%20ltd&etslf=6757"
                    target="_blank"
                    rel="noreferrer"
                    className="social_icon_wrapper"
                  >
                    {/* <Pintrstgrey className="banner_social_icons" /> */}
                    <img src={pintrst} alt="" className="banner_social_icons" />
                  </a>
                </li>
              </ul>
            </div>
            : ""}

        {/* <div className={width <= 1279 ? "my_container" : ""}> */}
        <div className="my_container">
          <div className="banner_slides_wrapper">
            <Swiper
              modules={[Autoplay, Pagination]}
              spaceBetween={20}
              pagination={{
                el: ".my-custom-pagination-div",
                clickable: true,
                renderBullet: (index, className) => {
                  return '<span class="' + className + '">' + "" + "</span>";
                },
              }}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              // loop={true}
            >
              {bannerImgList}
            </Swiper>
            <div class="my-custom-pagination-div" />
          </div>
        </div>
      </section>

      <section className="homesec2 extra_space_issue" data-aos="fade-up">
        <div className="row m-0">
          <div className="col-lg-6 col1">
            <h5 className="section_name">About Us</h5>
            <h2 className="section_title">
              Raymond, A Diversified Group Shaping A Stronger & Better Tomorrow.
            </h2>
            <p className="desc_text">
              With roots dating back to 1925, as a small woollen mill in Thane
              (Maharashtra), manufacturing coarse woollen blankets, Raymond
              Brand has evolved into a leading manufacturer of the finest
              fabrics in the world. Reckoned for its pioneering innovations and
              having enjoyed the patronage of millions of consumers, Raymond is
              amongst the trusted brands in India.
            </p>
            {/* <p className="section_desc">
              With an eclectic portfolio of brands in the menswear space,
              Raymond Apparel has brands such as Raymond Ready to Wear, Park
              Avenue, ColorPlus, Parx and, Ethnix by Raymond.
              <br />
              <br />
              Being a vertically and horizontally integrated manufacturer of
              textiles, Raymond has strong fibre-to-fabric manufacturing
              capabilities and is a textile powerhouse with state-of-the-art
              modern infrastructure. Raymond offers an exquisite range of
              shirting and suiting fabrics across a plethora of options such as
              Worsted fabrics, Cotton, Wool blends, Linen and Denim. With over
              1500 stores spread across 600 towns and an expansive network of
              over 20,000 points of sale in India, Raymond and its brands are
              also available in tier IV & V cities. Over the years, the brand
              has emerged as a preferred choice for top design houses globally.
              Raymond has also been a leading player in High-value cotton
              shirting fabrics and is the No. 1 brand in the OTC space.
              <br />
              <br />
              Raymond Group also has a significant presence in the B2B space
              through its garmenting business. Our state-of-the-art & wholly
              owned subsidiaries such as Silver Spark Apparel Ltd, Celebrations
              Apparel Ltd & Everblue Apparel Ltd craft suits, trousers, shirts &
              Jeans for leading fashion labels across the world and is the only
              manufacturer of full canvas premium jackets in India.
              <br />
              <br />A formidable player in the Denim category, Raymond is among
              the leading producer & preferred suppliers of high-quality Ring
              Denim to the world’s renowned Denim brands. Having established its
              dominance in Textiles, Raymond pioneered the innovative concept of
              customised clothing, ‘Raymond Made To Measure’ that offers
              luxurious service to discerning customers to personalize their
              ensemble. The Raymond Group has a strong presence in the Consumer
              Care space via Raymond Consumer Care. The company mainly caters to
              the male grooming segment in fragrances, soaps and shampoos
              through the Park Avenue brand. The Consumer Care business also has
              a strong positioning in the sexual wellness segment through its
              KamaSutra brand.
              <br />
              <br />
              Raymond Ltd. ventured into the engineering business in 1949. JK
              Files and Engineering Ltd. enjoys a leadership position as the
              world’s largest manufacturer of steel files with a share of over
              25% of global capacity in 2020, with a presence in over 55
              countries. It is a market leader in the file segment in India with
              a market share of over 60% by sales volume in Fiscal 2021. Through
              its Group Company Ring Plus Aqua Ltd (RPAL), it is in the
              manufacturing, sale and distribution of auto components and
              engineering products such as Ring Gears, Flexplates and Water pump
              bearings. RPAL has the market leadership position in Ring Gears
              with a volume share of more than 50% in Passenger Vehicles and
              more than 45% in Commercial Vehicles in India in Fiscal 2021. In
              Flexplates, RPAL is the sole domestic manufacturer in India.
              <br />
              <br />
              Recently, Raymond Group forayed into the real estate development
              business. With the central theme of <strong>‘Go Beyond’</strong>,
              the company has undertaken the project to build quality housing
              for all. A large gated community named{" "}
              <strong>‘Aspirational District’</strong> is spread over 14 acres
              that celebrate a new epoch in living.
            </p> */}
            <div className="btn_wrapper">
              <RightArrowButton
                linkTo="/our-company/about-us"
                ctaText="know more"
              />
            </div>
          </div>
          <div className="col-lg-6 col2">
            <img
              src={aboutusbelowbanner}
              alt="Our company"
              loading="lazy"
              width="622"
              height="704"
              className="our_company_img"
            />
            <div className="experience_box">
              <img
                src={aboutSmallimg}
                alt="Our company"
                loading="lazy"
                width="237"
                height="222"
                className="exp_img"
              />
              <div className="exp_text_wrapper">
                <span className="exp_no">Since</span>
                <span className="exp_text section_desc">1925</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="homesec3" data-aos="fade-up">
        <div className="re_imagine_flex">
          <figure className="re_imagine_left_wrapper">
            <img
              src={directorimage}
              alt="Raymond reimagine"
              className="re_imagine_left_img"
              loading="lazy"
              width={814}
              height={618}
            />
          </figure>
          <div className="re_imagine_content_wrapper">
            <img
              src={quoteComma}
              alt="Quote img"
              loading="lazy"
              className="quote_img"
            />
            <p className="re_imagine_para">
              The future lies in imagining the unimaginable! At Re-imagined
              Raymond, we aspire to achieve just the same. We aim to create not
              just offerings but an organisation that is built for the future.
            </p>
            <div className="re_imagine_quote_owner_details">
              <span>Gautam Hari Singhania</span>
              <span>Chairman & Managing Director</span>
              <span>Raymond Ltd.</span>
            </div>
            <div className="btn_wrapper">
              <RightArrowButton
                linkTo="/our-company/raymond-re-imagined"
                ctaText="read more"
                ctaWhite
              />
            </div>
          </div>
        </div>
      </section>

      <section className="homesec4" data-aos="fade-up">
        <h5 className="section_name">our Businesses</h5>
        <div className="row title_desc_wrapper">
          <div className="col-md-6 p-0">
            <h2 className="section_title">Our Quest For Excellence</h2>
          </div>
          <div className="col-md-6 p-0">
            <p className="section_desc">
              At the soul of Raymond resides the quest to delight our consumers
              with our high-quality offerings and pioneering innovations.
            </p>
          </div>
        </div>
        <div className="our_business_slider_wrapper">
          <Swiper
            modules={[Navigation, Autoplay, FreeMode]}
            navigation={{
              prevEl: ".our_business_slider_wrapper .left_btn",
              nextEl: ".our_business_slider_wrapper .right_btn",
            }}
            speed={600}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop={true}
            breakpoints={ourBusinessBreakpoints}
          >
            {ourBusinessList}
          </Swiper>
          <div className="navigation_wrapper">
            <div className="left_btn">
              <LeftArrowButton normalCta ctaText="previous" />
            </div>
            <div className="right_btn">
              <RightArrowButton normalCta ctaText="next" />
            </div>
          </div>
        </div>
      </section>

      <section className="homesec5" data-aos="fade-up">
        <h5 className="section_name">Our BRands</h5>
        <h2 className="section_title">
          Raymond has a portfolio of iconic brands across business verticals
        </h2>
        {width <= 767 ? (
          <div className="mb_brand_tabs_dropdown_wrapper">
            <select
              onChange={handleBrandTabChange}
              className="mb_brands_dropdown"
            >
              {mbOurBrandTabList}
            </select>
            <img
              src={selectdropdownarrow}
              alt="dropdown arrow"
              loading="lazy"
              className="selectdropdownarrow"
            />
          </div>
        ) : (
          <div className="brands_tabs_wrapper">{ourBrandTabList}</div>
        )}
        <div className="our_brands_swiper_wrapper">
          {ourBrandsSwiperList}
          {/* <div className="navigation_wrapper navigation_wrapper_next">
            <RightArrowButton normalCta ctaText="next" ctaWhite />
          </div> */}
          {width <= 767
            ? brandsDataArr?.map(
                (brandData, i) =>
                  brandData?.brands?.length > 1 && (
                    <div
                      className={`navigation_wrapper mb_navigation_wrapper ${
                        parseInt(ourBrandIndex) === i ? "active" : ""
                      }`}
                      key={i}
                    >
                      <div className={`left_btn left_btn_${i}`}>
                        <LeftArrowButton
                          normalCta
                          ctaText="previous"
                          ctaWhite
                        />
                      </div>
                      <div className={`right_btn navigation_wrapper_next_${i}`}>
                        <RightArrowButton normalCta ctaText="next" ctaWhite />
                      </div>
                    </div>
                  )
              )
            : brandsDataArr?.map(
                (brandData, i) =>
                  brandData.brands?.length > 3 && (
                    <div
                      className={`navigation_wrapper navigation_wrapper_next navigation_wrapper_next_${i} ${
                        parseInt(ourBrandIndex) === i ? "active" : ""
                      }`}
                      key={i}
                    >
                      <RightArrowButton normalCta ctaText="next" ctaWhite />
                    </div>
                  )
              )}
        </div>
      </section>

      <section className="homesec6" data-aos="fade-up">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 p-0">
              <h5 className="section_name">News Center</h5>
              <h2 className="section_title">News & Media</h2>
              <p className="section_desc">
                What’s happening at Raymond? Read our latest news stories
                encompassing latest business updates.
              </p>
              <div className="btn_wrapper">
                <RightArrowButton
                  linkTo="/newsroom/news-and-media"
                  ctaText="know more"
                />
              </div>
            </div>
            <div className="col-md-7 col2 p-0">
              <div className="news_swiper_wrapper">
                <Swiper
                  modules={[Navigation]}
                  navigation={{
                    prevEl: ".news_swiper_wrapper .left_btn",
                    nextEl: ".news_swiper_wrapper .right_btn",
                  }}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    767: {
                      slidesPerView: 1.5,
                      spaceBetween: 0,
                    },
                    992: {
                      slidesPerView: 2,
                    },
                    1930: {
                      slidesPerView: 2.4,
                    },
                  }}
                >
                  {newsAndMediaList}
                </Swiper>
                <div className="navigation_wrapper">
                  <div className="left_btn">
                    <LeftArrowButton normalCta ctaText="previous" />
                  </div>
                  <div className="right_btn">
                    <RightArrowButton normalCta ctaText="next" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="homesec7" data-aos="fade-up">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col2 order-md-2">
              <h5 className="section_name">Investors</h5>
              <h2 className="section_title">Investor Relations</h2>
              <p className="section_desc">
                We uphold transparency with our investors by sharing
                developments on our strategic initiatives and financial results
                with timely communication that enables them to make informed
                decisions.
              </p>
              <div className="btn_wrapper">
                <RightArrowButton
                  ctaText="Know More"
                  linkTo="/investor/disclosures-under-regulation-46-of-the-lodr/corporate-governance"
                />
              </div>
            </div>
            <div className="col-md-7 col1 order-md-1 stock_wrapper">
              <img
                src={investorGraph}
                alt="Investor relations graph"
                className="investordummyimg"
              />
              <div className="stock_price">
                ₹ {Math.round(stock.pricecurrent)}{" "}
                <span>
                  {Math.sign(parseInt(stock.pricepercentchange)) > 0 ? (
                    <img
                      className="updown_arrow"
                      alt="down arrow stock"
                      src={stockup}
                    />
                  ) : (
                    <img
                      className="updown_arrow"
                      alt="down arrow stock"
                      src={stockdown}
                    />
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="homesec8" data-aos="fade-up">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 col1">
              <h5 className="section_name">Beyond Business</h5>
              <h2 className="section_title">Beyond Business</h2>
              <p className="section_desc">
                Raymond Group is committed to building a purpose-led future and
                being a socially responsible corporate citizen. Through its
                various social initiatives, Raymond strives to create
                opportunities for all that will empower them to rise to their
                fullest potential.
              </p>
              {width > 767 && (
                <div className="navigation_wrapper">
                  <div className="left_btn">
                    <LeftArrowButton normalCta ctaText="previous" />
                  </div>
                  <div className="right_btn">
                    <RightArrowButton normalCta ctaText="next" />
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-8 col2">
              <Swiper
                modules={[Navigation]}
                navigation={{
                  prevEl: ".homesec8 .left_btn",
                  nextEl: ".homesec8 .right_btn",
                }}
                breakpoints={sustainabilityBreakpoints}
              >
                {susList}
              </Swiper>
              {width <= 767 && (
                <div className="navigation_wrapper">
                  <div className="left_btn">
                    <LeftArrowButton normalCta ctaText="previous" />
                  </div>
                  <div className="right_btn">
                    <RightArrowButton normalCta ctaText="next" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {instapost.length > 0 && (
        <section className="homesec9" data-aos="fade-up">
          <div className="text_container">
            <h5 className="section_name">Our Social Presence</h5>
            <h2 className="section_title">Life @Raymond on Instagram</h2>
          </div>
          <div className="insta_swiper_wrapper">
            <Swiper
              modules={[Navigation]}
              navigation={{
                prevEl: ".insta_swiper_wrapper .left_btn",
                nextEl: ".insta_swiper_wrapper .right_btn",
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                340: {
                  slidesPerView: 1.1,
                },
                530: {
                  slidesPerView: 1.6,
                },
                767: {
                  slidesPerView: 2.5,
                },
                1020: {
                  slidesPerView: 3,
                },
              }}
            >
              {/* <SwiperSlide>
              <img
                src={instaimg1}
                alt="Insta img"
                className="instaimg"
                loading="lazy"
                width={450}
                height={450}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={instaimg2}
                alt="Insta img"
                className="instaimg"
                loading="lazy"
                width={450}
                height={450}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={instaimg1}
                alt="Insta img"
                className="instaimg"
                loading="lazy"
                width={450}
                height={450}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={instaimg2}
                alt="Insta img"
                className="instaimg"
                loading="lazy"
                width={450}
                height={450}
              />
            </SwiperSlide> */}
              {instapostlist}
            </Swiper>
            <div className="navigation_wrapper">
              <div className="left_btn">
                <LeftArrowButton normalCta ctaText="previous" />
              </div>
              <div className="right_btn">
                <RightArrowButton normalCta ctaText="next" />
              </div>
            </div>
          </div>
        </section>
      )}
      <FsLightbox toggler={isVideoModalOpen} sources={[vidLink]} />

      {/* <div className="dummy"></div> */}
    </>
  );
};

export default Homepage;
