import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { request } from "../../utils/axios-utils";
import parse from "html-react-parser";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import "./Sustainability.scss";
import Loader from "../../components/Loader/Loader";
import { susBlocksImageUrl } from "../../helpers/utils";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const Sustainability = () => {
  const { width } = useWindowDimensions();

  const { susSlug } = useParams();
  const [bannerDetails, setBannerDetails] = useState(null);
  const [susBlockData, setSusBlockData] = useState([]);

  const {
    isLoading,
    isFetching,
    data: susData,
    refetch: refetchSusData,
  } = useQuery(
    "sus-data",
    async () => {
      return request({ url: `/sustainability/${susSlug}`, method: "get" });
    },
    {
      enabled: false,
    }
  );

  useEffect(() => {
    if (susSlug) {
      refetchSusData();
    }
  }, [susSlug]);
  useEffect(() => {
    if (susData) {
      const {
        data: { data },
      } = susData;
      if (data.length > 0) {
        setBannerDetails(data[0]);
        if (data[0]["blocks"].length > 0) {
          setSusBlockData(data[0]["blocks"]);
        }
      }
    }
  }, [susData]);
  // const bannerArr = [
  //   {
  //     id: 1,
  //     img: Jktrustban,
  //     firsthead: "Home | CSR | JK Trust",
  //     desc: "The Raymond group has always strived to contribute to different sections of the society. Beyond business, the objective of Raymond's social initiatives are conceptualized to create inclusive growth for the lesser privileged sections of the society.",
  //   },
  // ];

  // const trustArr = [
  //   {
  //     img: jkimage1,
  //     heading: "JK Trust Gram Vikas Yojana",
  //     desc: "The mission of JK Trust Gram Vikas Yojana is to significantly improve the quality of life in rural areas through a Cattle Breed Improvement Programme (CBIP). The surplus milk production achieved through this programme has helped to reduce infant mortality and malnutrition amongst children, creating employment and thus alleviating poverty. This social initiative benefits over 4.5 million people across 45,000 villages in 11 states of India.",
  //   },
  //   {
  //     img: jkimage2,
  //     heading: "JK BovaGenix",
  //     desc: "This first-of-its-kind breeding program utilizing in-vitro fertilization of selected indigenous cattle breeds was launched on 20th July, 2016. JK BovaGenix aims to achieve 1,000 IVF pregnancies within a year of its commissioning and scale it to 10,000 by 2020. This pioneering initiative is conceptualized to produce genetically superior cattle breeds at a rapid pace, thereby enhancing the milk yield in a short period of time. Cattle rearing and agriculture have a huge interdependency and being an agrarian society, this initiative assumes significant importance for rural India.",
  //   },
  //   {
  //     img: jkimage3,
  //     heading: "Community Initiatives -Skilled Tailoring Institute by Raymond",
  //     desc: "A unique employment linked CSR initiative of Raymond is the Skilled Tailoring Institute by Raymond (STIR) conceptualised to train unemployed youth, women, minority community and lesser privileged sections of the society in the art and science of tailoring. The initiative aims to upgrade the current skill-set and encourages employment and entrepreneurship amongst youth. STIR signed a Memorandum of Understanding with DGE&T (Directorate General of Employment and Training) in 2014.",
  //   },
  //   {
  //     img: jkimage4,
  //     heading: "Skill Development - Raymond Tailoring Hubs",
  //     desc: "Raymond is also taking measures to create a robust tailoring ecosystem to skill 1 lakh tailors in the next 5 years. Setting up 25 Tailoring Hubs in FY 17-18 and taking the tally to 50 hubs by 2020. Currently, there are 9 Tailoring Hubs located at Mumbai, Delhi, Bihta, Jamshedpur, Jaipur, Nashik, Villupuram, Ranchi and Bengaluru Certified 955 tailors under the Skill India initiative and there is a plan to train 20 thousand tailors during the year 2017-18.",
  //   },
  // ];

  // const bannerList = bannerArr.map((item, i) => (
  //   <div className="row" key={i}>
  //     <div className="col-lg-8">
  //       {width <= 834 ? (
  //         <div className="text_container">
  //           <div className="breadcrumb">{item.firsthead}</div>
  //           <div className="heading">JK Trust.</div>
  //           <div className="desc">{item.desc}</div>
  //         </div>
  //       ) : null}
  //       <img
  //         src={item.img}
  //         alt="raymond Brand img"
  //         className="mainimg"
  //         loading="lazy"
  //         width={1023}
  //         height={659}
  //       />
  //     </div>
  //     <div className="col-lg-4 flex_box">
  //       {width > 834 ? (
  //         <div className="text_container">
  //           <div className="breadcrumb">{item.firsthead}</div>
  //           <div className="heading">JK Trust.</div>
  //           <div className="desc">{item.desc}</div>
  //         </div>
  //       ) : null}
  //     </div>
  //   </div>
  // ));

  const trustList =
    susBlockData.length > 0 ? (
      susBlockData.map(({ title, description, image }, i) => {
        if (i % 2 === 0) {
          return (
            <div className="row row1" key={i}>
              <div className="col-lg-6 flex_box">
                <div className="text_container">
                  <div className="heading">{title}</div>
                  {/* This data is static to show how list will render you can comment <ul> tag 
              and uncomment below line for dynamic while integration */}
                  {width > 834 ? (
                    <ul className="desc custom-li">{parse(description)}</ul>
                  ) : null}
                  {/* {width > 834 ? <div className="desc">{item.desc}</div>  */}
                </div>
              </div>
              <div className="col-lg-6 image_container">
                <div className="zoomout">
                  <img className="inner_img" src={susBlocksImageUrl + image} alt="raymond beyond business img"/>
                </div>
                {/* This data is static to show how list will render you can comment <ul> tag 
              and uncomment below line for dynamic while integration */}
                {width <= 834 ? (
                  <ul className="desc custom-li">{parse(description)}</ul>
                ) : null}
                {/* {width <= 834 ? <div className="desc">{item.desc}</div> : null} */}
              </div>
            </div>
          );
        } else {
          return (
            <div className="row row2" key={i}>
              <div className="col-lg-6 order-lg-2 flex_box2">
                <div className="text_container">
                  <div className="heading">{title}</div>
                  {width > 834 ? (
                    <div className="desc custom-li">{parse(description)}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-6 order-lg-1 image_container">
                <div className="zoomout">
                  <img className="inner_img" src={susBlocksImageUrl + image} alt="raymond beyond business img" />
                </div>
                {width <= 834 ? (
                  <div className="desc custom-li">{parse(description)}</div>
                ) : null}
              </div>
            </div>
          );
        }
      })
    ) : (
      <h3 className="err_message">No block data found</h3>
    );

  if (isLoading || isFetching) {
    return <Loader />;
  }
  return (
    <>
    <MetaDecorator metaTitle="Welcome to Raymond | Beyond Business" />
      <section className="jk_sec1" data-aos="fade-in">
        <div className="my_container">
          {bannerDetails && (
            <div className="row">
              <div className="col-lg-8">
                {width <= 834 ? (
                  <div className="text_container">
                    <BreadCrumb
                      linkTwoText="Beyond Business"
                      linkTwoDisabled
                      // linkThreeText={bannerDetails.category}
                      // linkThreeDisabled
                      linkFourText={bannerDetails.slug}
                      linkFourDisabled
                    />
                    <div className="heading">{bannerDetails.title}</div>
                    <div className="desc">
                      {parse(bannerDetails.description)}
                    </div>
                  </div>
                ) : null}
                <img
                  src={susBlocksImageUrl + bannerDetails.image}
                  alt="raymond Brand img"
                  className="mainimg"
                  width={1023}
                  height={659}
                />
              </div>
              <div className="col-lg-4 flex_box">
                {width > 834 ? (
                  <div className="text_container">
                    <BreadCrumb
                      linkTwoText="Beyond Business"
                      linkTwoDisabled
                      // linkThreeText={bannerDetails.category}
                      // linkThreeDisabled
                      linkFourText={bannerDetails.slug}
                      linkFourDisabled
                    />
                    <div className="heading">{bannerDetails.title}</div>
                    <div className="desc">
                      {parse(bannerDetails.description)}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </section>

      <section className="jk_sec2" data-aos="fade-in">
        <div className="my_container">{trustList}</div>
      </section>
    </>
  );
};

export default Sustainability;
