import React, { useEffect, useState } from "react";
import "../common-styles/GovernanceDirectorInner.scss";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import useGetDirectorInnerData from "../../hooks/useGetDirectorInnerData";
import parse from "html-react-parser";
import Loader from "../../components/Loader/Loader";
import { convertSlugtoString } from "../../helpers/utils";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const InvestorsDirectorInner = () => {
  const { isLoading, directorsInnerData } = useGetDirectorInnerData(
    "/investors/policy/directors/",
    "inevstor-directors-inner"
  );
  const [slugSegmentsArr, setSlugSegmentsArr] = useState([]);
  useEffect(() => {
    setSlugSegmentsArr(window.location.pathname.split("/"));
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <main>
      <MetaDecorator metaTitle="Welcome to Raymond | Investor" />
      {directorsInnerData !== null && (
        <section className="director_inner_sec1" data-aos="fade-in">
          <div className="my_container">
            <BreadCrumb
              linkTwoText="investors"
              linkTwoDisabled
              linkThreeText={convertSlugtoString(slugSegmentsArr[2])}
              linkThreeDisabled
              linkFourText={convertSlugtoString(slugSegmentsArr[3])}
              linkFour={`/investor/${slugSegmentsArr[2]}/${slugSegmentsArr[3]}`}
              linkFiveText={convertSlugtoString(slugSegmentsArr[4])}
              linkFive={`/investor/${slugSegmentsArr[2]}/${slugSegmentsArr[3]}/${slugSegmentsArr[4]}`}
              linkSixText={convertSlugtoString(slugSegmentsArr[5])}
              linkSixDisabled
            />
            <h1 className="govover_heading">{directorsInnerData?.name}</h1>
            <div className="govover_desc">
              {directorsInnerData?.description
                ? parse(directorsInnerData?.description)
                : null}
            </div>
          </div>
        </section>
      )}
    </main>
  );
};

export default InvestorsDirectorInner;
