import React from "react";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import "./InnerPageBanner.scss";

const InnerPageBanner = ({
  bannerHeading,
  bannerDesc,
  bannerImg,
  linkTwoText,
  linkTwoDisabled,
  linkThreeText,
  linkThreeDisabled,
  linkThree,
  linkFourText,
  linkFourDisabled,
  hideImg,
}) => {
  return (
    <div className="common_banner_content_wrapper">
      <div className="my_container">
        <div className="row">
          <div className="col-lg-6 col1">
            <BreadCrumb
              linkTwoText={linkTwoText}
              linkTwoDisabled={linkTwoDisabled}
              linkThreeText={linkThreeText}
              linkThree={linkThree}
              linkThreeDisabled={linkThreeDisabled}
              linkFourText={linkFourText}
              linkFourDisabled={linkFourDisabled}
            />
            <h1 className="common_banner_heading">{bannerHeading}</h1>
            <p className="common_banner_desc">{bannerDesc}</p>
          </div>
          <div className="col-lg-6 col2">
            {hideImg ? null : (
              <img
                src={bannerImg}
                alt="Raymond Banner"
                className="common_banner_img"
                width={807}
                height={539}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InnerPageBanner;
