import React from "react";
import "../../common-styles/GovernanceDirectors.scss";

import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";

import useGetDirectorsData from "../../../hooks/useGetDirectorsData";
import Loader from "../../../components/Loader/Loader";
import { investorImageUrl } from "../../../helpers/utils";
import BoardOfDirectorBox from "../../../components/BoardOfDirectorBox/BoardOfDirectorBox";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";

const GovernanceDirectors = () => {
  const { isLoading, directorsData } = useGetDirectorsData(
    "/investors/policy/",
    "governance-directors"
  );

  const boardOfDirectorsList =
    directorsData?.templateData?.length > 0
      ? directorsData?.templateData?.map(
          ({ name, slug, image, designation }, i) => (
            <BoardOfDirectorBox
              key={i}
              directorImg={investorImageUrl + image}
              directorName={name}
              directorDesignation={designation}
              linkTo={slug}
            />
          )
        )
      : null;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <main>
    <MetaDecorator metaTitle="Welcome to Raymond | Leaders" />
      {directorsData && (
        <section className="directorssec1" data-aos="fade-in">
          <div className="my_container">
            <div className="text_container">
              <BreadCrumb
                linkTwoText="our company"
                linkTwoDisabled
                linkThreeText={directorsData.investorCategory.title}
                linkThree={`/our-company/${directorsData.investorCategory.slug}`}
                linkFourText={directorsData.name}
                linkFourDisabled
              />
              <h1 className="directors_heading">{directorsData?.title}</h1>
            </div>
            <div className="directors_wrapper">
              <div className="directors_flex">{boardOfDirectorsList}</div>
            </div>
          </div>
        </section>
      )}
    </main>
  );
};
export default GovernanceDirectors;
