import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { request } from "../../utils/axios-utils";
import parse from "html-react-parser";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { awardimg, businessban, dropdownarrow, raymondimg } from "../../images";
import RightArrowButton from "../../components/RightArrowButton/RightArrowButton";
import "./OurBusiness.scss";
import Loader from "../../components/Loader/Loader";
import { businessImageUrl } from "../../helpers/utils";
// import { useLocation } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const bannerArr = [
  {
    id: 1,
    img: businessban,
    firsthead: "HOME | OUR BUSINESSES",
    heading: "Our Businesses",
    desc: "As a group, Raymond is involved in numerous businesses and has a portfolio of well-known brands in Textiles, Apparel, Denim, Consumer Care, Engineering and Real Estate.",
  },
];

const businessTabArr = [
  {
    tabName: "tab1",
    list: [
      {
        desc: "hello",
      },
    ],
  },
  {
    tabName: "tab2",
    list: [
      {
        desc: "hello",
      },
    ],
  },
  {
    tabName: "tab3",
    list: [
      {
        desc: "hello",
      },
    ],
  },
  {
    tabName: "tab4",
    list: [
      {
        desc: "hello",
      },
    ],
  },
  {
    tabName: "tab5",
    list: [
      {
        desc: "hello",
      },
    ],
  },
];

const businessArr = [
  {
    id: 1,
    img: raymondimg,
    list: {
      title: "Raymond",
      desc: "Raymond is a diversified group with majority Brand Raymond has pushed the benchmarks of quality, leadership and extraordinary product across fashion. leadership and extraordinary product across fashion",
    },
  },
  {
    id: 2,
    img: raymondimg,
    list: {
      title: "Raymond",
      desc: "Raymond is a diversified group with majority Brand Raymond has pushed the benchmarks of quality, leadership and extraordinary product across fashion. leadership and extraordinary product across fashion",
    },
  },
];

const fetchBusinesseData = () => {
  return request({ url: "/businesses", method: "get" });
};
const OurBusiness = () => {
  // const location = useLocation();
  // const boxId = location.state?.boxId;
  const { width } = useWindowDimensions();
  const [businessDataArr, setBusinessDataArr] = useState([]);
  const [businessIndex, setBusinessIndex] = useState(0);

  // const brandArr = [
  //   {
  //     id: 1,
  //     img: raymondimg,
  //     heading: "Realty",
  //     desc: "Brand Raymond has pushed the benchmarks of quality, leadership and extraordinary product across fashion, apparel and lifestyle industries for years now. From clothing the Complete Man in impeccable sartorial flair, the legacy of Raymond now enters into the Real Estate space with Raymond Realty. The same dedication to quality, minute details and absolute style, will now take a form in luxurious residential spaces. The fabric of choice this time is brick and mortar. They have woven into residences that offer the man-about-town and his family a COMPLETE LIFESTYLE.",
  //   },
  //   {
  //     id: 2,
  //     img: parximg,
  //     heading: "Lifestyle",
  //     desc: "Brand Raymond has pushed the benchmarks of quality, leadership and extraordinary product across fashion, apparel and lifestyle industries for years now. From clothing the Complete Man in impeccable sartorial flair, the legacy of Raymond now enters into the Real Estate space with Raymond Realty. The same dedication to quality, minute details and absolute style, will now take a form in luxurious residential spaces. The fabric of choice this time is brick and mortar. They have woven into residences that offer the man-about-town and his family a COMPLETE LIFESTYLE.",
  //   },
  //   {
  //     id: 3,
  //     img: parximg,
  //     heading: "Consumer Care",
  //     desc: "Brand Raymond has pushed the benchmarks of quality, leadership and extraordinary product across fashion, apparel and lifestyle industries for years now. From clothing the Complete Man in impeccable sartorial flair, the legacy of Raymond now enters into the Real Estate space with Raymond Realty. The same dedication to quality, minute details and absolute style, will now take a form in luxurious residential spaces. The fabric of choice this time is brick and mortar. They have woven into residences that offer the man-about-town and his family a COMPLETE LIFESTYLE.",
  //   },
  //   {
  //     id: 4,
  //     img: parximg,
  //     heading: "jK Files & Engineering",
  //     desc: "Brand Raymond has pushed the benchmarks of quality, leadership and extraordinary product across fashion, apparel and lifestyle industries for years now. From clothing the Complete Man in impeccable sartorial flair, the legacy of Raymond now enters into the Real Estate space with Raymond Realty. The same dedication to quality, minute details and absolute style, will now take a form in luxurious residential spaces. The fabric of choice this time is brick and mortar. They have woven into residences that offer the man-about-town and his family a COMPLETE LIFESTYLE.",
  //   },
  //   {
  //     id: 5,
  //     img: parximg,
  //     heading: "UCO Denim",
  //     desc: "Brand Raymond has pushed the benchmarks of quality, leadership and extraordinary product across fashion, apparel and lifestyle industries for years now. From clothing the Complete Man in impeccable sartorial flair, the legacy of Raymond now enters into the Real Estate space with Raymond Realty. The same dedication to quality, minute details and absolute style, will now take a form in luxurious residential spaces. The fabric of choice this time is brick and mortar. They have woven into residences that offer the man-about-town and his family a COMPLETE LIFESTYLE.",
  //   },
  // ];

  const bannerList = bannerArr.map((item, i) => (
    <div className="row" key={i}>
      <div className="col-lg-8">
        {width <= 834 ? (
          <div className="text_container">
            <BreadCrumb linkTwoText={item.heading} linkTwoDisabled />
            <div className="heading">{item.heading}</div>
            <div className="desc">{item.desc}</div>
          </div>
        ) : null}
        <img
          src={item.img}
          alt="raymond business img"
          className="mainimg"
          loading="lazy"
        />
      </div>
      <div className="col-lg-4 flex_box">
        {width > 834 ? (
          <div className="text_container">
            <BreadCrumb linkTwoText={item.heading} linkTwoDisabled />
            <div className="heading">{item.heading}</div>
            <div className="desc">{item.desc}</div>
          </div>
        ) : null}
      </div>
    </div>
  ));

  const businessTabList = businessDataArr.map(({ title, _id }, i) => (
    <span
      key={i}
      onClick={() => {
        setBusinessIndex(i);
        scrollToId(_id);
      }}
      className={`business_tab ${businessIndex == i ? "active" : ""} `}
    >
      {title}
    </span>
  ));

  const mbbusinessTabList = businessDataArr.map(({ title }, i) => (
    <option value={title} key={i} data-awardindexid={i}>
      {title}
    </option>
  ));

  const { isLoading, data: businesseData } = useQuery(
    "businesse-data",
    fetchBusinesseData
  );

  const scrollToId = (_id) => {
    const element = document.getElementById(_id);
    const headerOffset = 210;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (businesseData) {
      const {
        data: { data },
      } = businesseData;
      if (data.length > 0) {
        setBusinessDataArr(data);
      }
    }
  }, [businesseData]);

  useEffect(() => {
    // if (boxId) {
    //   const domBoxId = document.getElementById(boxId);
    //   if (boxId === domBoxId?.id) {
    //     applyScroll(boxId, -50);
    //   }
    // }
  }, [businessDataArr]);

  const brandList =
    businessDataArr.length > 0 ? (
      businessDataArr.map(({ title, description, image, website, _id }, i) => (
        <>
          {width > 767 ? (
            <div
              className="col-lg-6 flex_box"
              key={i}
              data-aos="fade-in"
              id={`${_id}`}
              // ref={element1}
            >
              {width <= 834 ? <div className="mobheading">{title}</div> : null}
              <div className="zoomout">
                <img
                  src={businessImageUrl + image}
                  alt="raymond business img"
                  className="mainimg"
                  width={1544}
                  height={1074}
                />
              </div>
              <div className="text_container">
                {width > 834 ? <div className="heading">{title}</div> : null}
                <div className="desc">{parse(description)}</div>
                {website && (
                  <div className="cta_wrapper">
                    <RightArrowButton
                      anchorCta
                      anchorLink={website}
                      target="_self"
                      ctaText="Know More"
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div
              className={`col-lg-6 flex_box ${
                businessIndex === i ? "active" : ""
              }`}
              key={i}
              data-aos="fade-in"
              id={`${_id}`}
              // ref={element1}
            >
              {width <= 834 ? <div className="mobheading">{title}</div> : null}
              <div className="zoomout">
                <img
                  src={businessImageUrl + image}
                  alt="raymond business img"
                  className="mainimg"
                  width={1544}
                  height={1074}
                />
              </div>
              <div className="text_container">
                {width > 834 ? <div className="heading">{title}</div> : null}
                <div className="desc">{parse(description)}</div>
                {website && (
                  <div className="cta_wrapper">
                    <RightArrowButton
                      anchorCta
                      anchorLink={website}
                      ctaText="Know More"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      ))
    ) : (
      <h3 className="err_message">No Business data found</h3>
    );

  useEffect(() => {
    // window.scrollTo(0, 0);
  });

  if (isLoading) {
    return <Loader />;
  }

  const handleAwardTabChange = (e) => {
    const awardIndexId = e.target.selectedOptions[0].dataset.awardindexid;
    setBusinessIndex(Number(awardIndexId));
  };

  return (
    <>
    <MetaDecorator metaTitle="Welcome to Raymond | Our Businesses" />
      <section className="business_sec1" data-aos="fade-in">
        <div className="my_container">{bannerList}</div>
      </section>

      <section className="business_sec2 extra_space_issue" data-aos="fade-up">
        <div className="my_container">
          {width > 767 ? (
            <div className="business_tabs_wrapper">{businessTabList}</div>
          ) : (
            <div className="business_tabs_wrapper">
              <div className="mb_award_tabs_dropdown_wrapper">
                <select
                  onChange={handleAwardTabChange}
                  className="mb_awards_dropdown"
                >
                  {mbbusinessTabList}
                </select>
                <img
                  src={dropdownarrow}
                  alt="raymond dropdown logo"
                  className="downdropdownarrow"
                />
              </div>
            </div>
          )}
          {width > 767 ? (
            <div className="row">{brandList}</div>
          ) : (
            <div className="row">{brandList}</div>
          )}
        </div>
      </section>
    </>
  );
};

export default OurBusiness;
