import React, { useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { NavLink } from "react-router-dom";
// import { useGetOurBrandsData } from "../../hooks/useGetHomepageData";
import { ToastContainer, toast } from "react-toastify";
import * as yup from "yup";
import { mergeAll } from "ramda";
import axios from "axios";
import {
  arrowright,
  location,
  call,
  linkedin,
  instagram,
  facebook,
  twitter,
  youtube,
  shoponline,
  storelocator1,
} from "../../images";
import pinterest from "../../images/homepage/pinterest-5-xxl.png";
import "./Footer.scss";
import {
  aboutusurl,
  cookiepolicy,
  homepageurl,
  privacypolicy,
  termsofuse,
} from "../../helpers/constant-words";

const Footer = () => {
  const [values, setValues] = useState({
    email: "",
  });
  const [formError, setFormError] = useState({});
  const [openMenu, setOpenMenu] = useState(false);
  const { width } = useWindowDimensions();
  const [index, setIndex] = useState(null);
  // const [menuItemActive, setMenuItemActive] = useState(false);
  // const { brandsData } = useGetOurBrandsData();

  const subscribeSchema = yup.object().shape({
    email: yup
      .string()
      .trim()
      .required("Email is required")
      .email("Email is invalid"),
  });

  const handleFormSubmit = async () => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/forms/subscribe`,
      values
    );
    if (data.status === 200) {
      setValues((prevState) => {
        return {
          ...prevState,
          email: "",
        };
      });
      toast.success(data.message, {
        autoClose: 5000,
      });
      return;
    }
    if (data.status >= 400 || data.status <= 499) {
      toast.error(data.message, {
        autoClose: 5000,
      });
      // setLoading(false);

      return;
    }
  };

  const handleSubmit = () => {
    subscribeSchema
      .validate(values, { abortEarly: false })
      .then(() => {
        setFormError({});
        handleFormSubmit();
      })
      .catch((err) => {
        if (err.name === "ValidationError") {
          const errs = mergeAll(
            err.inner.flatMap((e) => ({ [e.path]: e.errors }))
          );
          setFormError(errs);
        }
      });
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handlePlusClick = (i) => {
    if (index === false) {
      setIndex(i);
    } else {
      setIndex(!index);
    }
  };

  //Link arrays
  const companyArr = [
    {
      id: 1,
      main: "Our Company",
      subhead: "About Us",
      subhead1: "Raymond Re-Imagined",
      subhead2: "Leaders",
      subhead3: "Awards & Accolades",
      subhead4: "Our Reach",
    },
  ];

  // const sustainArr = [
  //   {
  //     id: 1,
  //     main: "Beyond Business",
  //     subhead: "CSR",
  //     subhead1: "ESG",
  //   },
  // ];

  const newsroomArr = [
    {
      id: 1,
      main: "Newsroom",
      subhead: "News & Media",
      subhead1: "Media contact",
      // subhead2: "Career",
    },
  ];

  const quickArr = [
    {
      id: 1,
      main: "Quick Links",
      subhead: "Our Businesses",
      subhead1: "Partner With Us",
      subhead2: "Career",
      // subhead3: "Newsroom",
      subhead4: "Contact",
      // subhead5: "FAQ",
      subhead6: "Terms of use",
      subhead7: "Privacy Policy",
      subhead8: "Cookie Policy",
    },
  ];

  const addressArr = [
    {
      id: 1,
      main: "Quick Links",
      subhead: "Our Businesses",
      subhead1: "Partner With Us",
      // subhead2: "Career",
      subhead3: "Newsroom",
      subhead4: "Contact",
      // subhead5: "FAQ",
      subhead6: "Terms of use",
      subhead7: "Privacy Policy",
    },
  ];

  const mbMenuArr = [
    {
      isLink: true,
      redirectLink: "/our-businesses",
      linkName: "Our Businesses",
      subMenu: [],
    },
    {
      linkName: "Our Company",
      subMenu: [
        {
          redirectLink: aboutusurl,
          subLinkName: "About Us",
        },
        {
          redirectLink: "/our-company/raymond-re-imagined",
          subLinkName: "Raymond Re-Imagined",
        },
        {
          redirectLink: "/our-company/awards-and-accolades",
          subLinkName: "Awards & Accolades",
        },
        {
          redirectLink: "/our-company/our-reach",
          subLinkName: "Our Reach",
        },
        {
          redirectLink: "/our-company/leaders",
          subLinkName: "Leaders",
        },
      ],
    },
    // {
    //   isLink: true,
    //   redirectLink: "/our-brands",
    //   linkName: "Our Brands",
    //   subMenu: [],
    // },
    {
      linkName: "Beyond Business",
      subMenu: [
        {
          redirectLink: "/beyond-business/csr/community-initiatives",
          subLinkName: "Community Initiatives",
        },
        {
          redirectLink: "/beyond-business/csr/education",
          subLinkName: "Education",
        },
        {
          redirectLink: "/beyond-business/csr/jk-trust",
          subLinkName: "Jk Trust",
        },
      ],
    },
    {
      linkName: "Newsroom",
      subMenu: [
        {
          redirectLink: "/newsroom/news-and-media",
          subLinkName: "News & Media",
        },
        {
          redirectLink: "/newsroom/media-contact",
          subLinkName: "Media Contact",
        },
      ],
    },
    // {
    //   isLink: true,
    //   redirectLink: "/investors",
    //   linkName: "Investors",
    //   subMenu: [],
    // },
    {
      isLink: true,
      redirectLink: "/career",
      linkName: "Career",
      subMenu: [],
    },
    {
      isLink: true,
      redirectLink: "/contact-us",
      linkName: "Contact",
      subMenu: [],
    },
    {
      isLink: true,
      redirectLink: termsofuse,
      linkName: "Terms of Use",
      subMenu: [],
    },
    {
      isLink: true,
      redirectLink: privacypolicy,
      linkName: "privacy policy",
      subMenu: [],
    },
    {
      isLink: true,
      redirectLink: cookiepolicy,
      linkName: "cookie policy",
      subMenu: [],
    },
  ];

  //Link arrays mapping
  const ourcompanyList = companyArr.map((item, i) => (
    <div className="container" key={i}>
      <p className="mainhead">{item.main}</p>
      <a href={aboutusurl} className="subhead">
        {item.subhead}
      </a>
      <a href="/our-company/raymond-re-imagined" className="subhead">
        {item.subhead1}
      </a>

      <a href="/our-company/awards-and-accolades" className="subhead">
        {item.subhead3}
      </a>
      <a href="/our-company/our-reach" className="subhead">
        {item.subhead4}
      </a>
      <a href="/our-company/leaders" className="subhead">
        {item.subhead2}
      </a>
    </div>
  ));

  // const sustainList = sustainArr.map((item, i) => (
  //   <div className="container" key={i}>
  //     <p className="mainhead">{item.main}</p>
  //     <a href="/csg" className="subhead">
  //       {item.subhead}
  //     </a>
  //     <a href="/esg" className="subhead">
  //       {item.subhead1}
  //     </a>
  //   </div>
  // ));

  // const ourbrandsList = brandsData?.data?.data.map(({ slug, title }, _id) => (
  //   <a href={`/our-brand/${slug}`} className="subhead" key={_id}>
  //     {title}
  //   </a>
  // ));

  const newsroomList = newsroomArr.map((item, i) => (
    <div className="container" key={i}>
      <p className="mainhead">{item.main}</p>
      <a href="/newsroom/news-and-media" className="subhead">
        {item.subhead}
      </a>
      <a href="/newsroom/media-contact" className="subhead">
        {item.subhead1}
      </a>
      {/* <a href="/career" className="subhead">
        {item.subhead2}
      </a> */}
    </div>
  ));

  const quickList = quickArr.map((item, i) => (
    <div className="container" key={i}>
      <p className="mainhead">{item.main}</p>
      <a href="/our-businesses" className="subhead">
        {item.subhead}
      </a>
      <a href="/partner-with-us" className="subhead">
        {item.subhead1}
      </a>
      <a href="/career" className="subhead">
        {item.subhead2}
      </a>
      {/* <a href="/newsroom" className="subhead">
          {item.subhead3}
        </a> */}
      <a href="/contact-us" className="subhead">
        {item.subhead4}
      </a>
      {/* <a href="/faq" className="subhead">
        {item.subhead5}
      </a> */}
      <a href={termsofuse} className="subhead">
        {item.subhead6}
      </a>
      <a href={privacypolicy} className="subhead">
        {item.subhead7}
      </a>
      <a href={cookiepolicy} className="subhead">
        {item.subhead8}
      </a>
    </div>
  ));

  const addressList = addressArr.map((item, i) => <div key={i}></div>);

  const mbMenuList = mbMenuArr.map((footerMenu, i) => (
    <div className="col-lg-3" key={i}>
      {footerMenu.isLink ? (
        <div className="mb_link_wrapper">
          <div className="mb_link_main_wrapper">
            <NavLink to={footerMenu.redirectLink} className="mb_route_text">
              {footerMenu.linkName}
            </NavLink>
          </div>
        </div>
      ) : (
        <div className="mb_link_wrapper">
          <div className="mb_link_main_wrapper">
            <div className="mb_route_text">
              {footerMenu.linkName}
              <span
                className={`plus_span ${index ? "plus_span" : null}`}
                onClick={() => {
                  handlePlusClick(i);
                }}
              >
                {index === i ? "-" : "+"}
              </span>
            </div>
          </div>
        </div>
      )}
      <div
        className={`box_content_wrapper ${
          index === i ? "box_content_active" : null
        }`}
      >
        {footerMenu.subMenu.map((subMenu, i) => (
          <div className="link_name_wrapper" key={i}>
            <NavLink to={subMenu.redirectLink} className="link_name">
              {subMenu.subLinkName}
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  ));

  return (
    <>
      {width > 600 ? (
        <section className="footer_sec1">
          <div className="my_container">
            <div className="container_wrapper">
              <div className="row">
                <div className="col-md-8 col-lg-8">
                  <div className="row">
                    <div className="col-md-8 col-lg-8">
                      <div className="row">
                        <div className="col-md-6 col-lg-6">
                          {ourcompanyList}
                        </div>
                        <div className="col-md-6 col-lg-6">
                          <div className="container">
                            {/* <p className="mainhead">Our Brands</p> */}
                            {/* {ourbrandsList} */}
                            {newsroomList}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col-md-6 col-lg-6">{newsroomList}</div> */}
                        {/* <div className="col-md-6 col-lg-6"></div> */}
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4">{quickList}</div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 container">
                  <div className="row">
                    <p className="mainhead">Get In Touch</p>
                    {/* <div className="text">
                      <input
                        type="text"
                        // role="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        placeholder="Enter email id"
                      />
                      <button onClick={handleSubmit}>
                        <img
                          alt="arrow img"
                          className="arrow_right"
                          src={arrowright}
                        />
                      </button>
                    </div> */}
                    <div className="error" style={{ color: "red" }}>
                      {formError.email}
                    </div>

                    <div className="text_icon_container">
                      <div className="location">
                        <img
                          className="icon"
                          src={location}
                          alt="raymond location icon"
                        />
                        <p className="icon_desc">
                          Raymond Limited, New Hind House, Narottam Morarjee
                          Marg, Ballard Estate, Mumbai – 400001
                        </p>
                      </div>
                      <div className="call">
                        <img
                          className="icon"
                          src={call}
                          alt="raymond call icon"
                        />
                        <a className="icon_desc" href="tel: 1800 222 001">
                          <span className="hover_effect">1800 222 001</span>
                          <br />
                          Monday To Saturday - 9:30AM To 6:30PM
                        </a>
                      </div>
                      <div className="call">
                        <img
                          className="icon"
                          src={shoponline}
                          alt="raymond call icon"
                        />
                        <a
                          className="icon_desc"
                          href="https://www.myraymond.com/home"
                        >
                          <span className="hover_effect">Shop Online</span>
                        </a>
                      </div>
                      {/* <div className="call">
                    <img
                      className="icon"
                      src={shoponline}
                      alt="raymond call icon"
                    />
                    <a
                      className="icon_desc"
                      href=""
                    >
                      <span className="hover_effect">Store Locator</span>
                    </a>
                  </div> */}
                    </div>
                    <div className="social_box">
                      <div className="mainhead">Follow Us</div>
                      <a
                        href="https://www.linkedin.com/company/raymond-limited?originalSubdomain=in"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img className="icon" src={linkedin} alt="social" />
                      </a>
                      <a
                        href="https://www.instagram.com/raymond_the_complete_man/?hl=en"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img className="icon" src={instagram} alt="social" />
                      </a>
                      <a
                        href="https://www.facebook.com/RaymondLimited/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img className="icon" src={facebook} alt="social" />
                      </a>
                      <a
                        href="https://twitter.com/theraymondltd?lang=en"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img className="icon" src={twitter} alt="social" />
                      </a>
                      <a
                        href="https://www.youtube.com/user/raymondlimited"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img className="icon" src={youtube} alt="social" />
                      </a>
                      <a
                        href="https://in.pinterest.com/Raymond_corp/?eq=raymond%20ltd&etslf=6757"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img className="icon" src={pinterest} alt="social" />
                      </a>
                    </div>
                    {addressList}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <footer className="footer">
          <div className="my_container">
            <div className="row">
              <div className={`mb_menu ${openMenu ? "mb_menu_active" : null}`}>
                <div className="mb_links_wrapper_cont">{mbMenuList}</div>
              </div>
              <div className="container">
                <p className="mainhead">Get In Touch</p>
                {/* <div className="text">
                  <input
                    type="text"
                    // role="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    placeholder="Enter email id"
                  />
                  <button onClick={handleSubmit}>
                    <img
                      alt="aroow img"
                      className="arrow_right"
                      src={arrowright}
                    />
                  </button>
                </div> */}
                <div className="error" style={{ color: "red" }}>
                  {formError.email}
                </div>
                <div className="text_icon_container">
                  <div className="location">
                    <img
                      className="icon"
                      src={location}
                      alt="raymond location icon"
                    />
                    <p className="icon_desc">
                      Raymond Limited, New Hind House, Narottam Morarjee Marg,
                      Ballard Estate, Mumbai – 400001
                    </p>
                  </div>
                  <div className="call">
                    <img className="icon" src={call} alt="raymond call icon" />
                    <a className="icon_desc" href="tel: 1800 222 001">
                      <span className="hover_effect">1800 222 001</span>
                      <br />
                      Monday To Saturday - 9:30AM To 6:30PM
                    </a>
                  </div>
                  <div className="call">
                    <img
                      className="icon"
                      src={shoponline}
                      alt="raymond call icon"
                    />
                    <a
                      className="icon_desc"
                      href="https://www.myraymond.com/home"
                    >
                      <span className="hover_effect">Shop Online</span>
                    </a>
                    </div>
                    <div className="store_locator">
                    <img
                      className="icon_1"
                      src={storelocator1}
                      alt="raymond call icon"
                    />
                    <a
                      className="icon_desc"
                      href="https://stores.myraymond.com/"
                      target="_blank"
                    >
                      <span className="hover_effect">Store Locator</span>
                    </a>
                  </div>
                </div>
                <div className="social_box">
                  <div className="mainhead">Follow Us</div>
                  <a
                    href="https://www.linkedin.com/company/raymond-limited/?originalSubdomain=in"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className="icon" src={linkedin} alt="social" />
                  </a>
                  <a
                    href="https://www.instagram.com/raymond_the_complete_man/?hl=en"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className="icon" src={instagram} alt="social" />
                  </a>
                  <a
                    href="https://www.facebook.com/RaymondLimited/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className="icon" src={facebook} alt="social" />
                  </a>
                  <a
                    href="https://twitter.com/theraymondltd?lang=en"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className="icon" src={twitter} alt="social" />
                  </a>
                  <a
                    href="https://www.youtube.com/user/raymondlimited"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className="icon" src={youtube} alt="social" />
                  </a>
                  <a
                    href="https://in.pinterest.com/Raymond_corp/?eq=raymond%20ltd&etslf=6757"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className="icon" src={pinterest} alt="social" />
                  </a>
                </div>
                {addressList}
              </div>
            </div>
          </div>
        </footer>
      )}
      <section className="footer_sec2">
        <div className="my_container">
          <div className="copyright">
            <p className="copyright_text">
              <a href={homepageurl}>Raymond</a> © All Rights Reserved - 2022
            </p>
            <p className="crafted_text">
              <a
                href="https://www.togglehead.in/"
                target="_blank"
                rel="noreferrer"
              >
                Crafted By Togglehead
              </a>
            </p>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default Footer;
