import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import "./LeadershipInterview.scss";
import "../../common-styles/GovernanceDirectors.scss";

//components
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import BoardOfDirectorBox from "../../../components/BoardOfDirectorBox/BoardOfDirectorBox";
//images
import leaderbannerimg from "../../../images/leaders/bannerimg.jpg";
import Loader from "../../../components/Loader/Loader";
import { request } from "../../../utils/axios-utils";
import { interviewImageUrl } from "../../../helpers/utils";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";

const fetchDirectorsData = () => {
  return request({ url: "/interviews", method: "get" });
};
const LeadershipInterview = () => {
  const [directorsDataArr, setDirectorsDataArr] = useState([]);
  const { isLoading, data: directorsData } = useQuery(
    "directors-data",
    fetchDirectorsData
  );

  useEffect(() => {
    if (directorsData) {
      const {
        data: { data },
      } = directorsData;
      if (data.length > 0) {
        setDirectorsDataArr(data);
      }
    }
  }, [directorsData]);

  const boardOfDirectorsList =
    directorsDataArr.length > 0 ? (
      directorsDataArr.map(({ name, designation, slug, thumbnail }, i) => (
        <BoardOfDirectorBox
          key={i}
          directorImg={interviewImageUrl + thumbnail}
          directorName={name}
          directorDesignation={designation}
          linkTo={slug}
          showKnowMore={false}
        />
      ))
    ) : (
      <h3 className="err_message">No Directors data found</h3>
    );

  if (isLoading) {
    return <Loader />;
  }
  return (
    <main>
    <MetaDecorator metaTitle="Welcome to Raymond | Leadership Interview" />
      <section className="leadshipsec1" data-aos="fade-in">
        <div className="my_container">
          <div className="row">
            <div className="col-lg-4 col1 order-lg-2">
              <div className="text_container">
                <BreadCrumb
                  linkTwoText="our company"
                  linkTwoDisabled
                  linkThreeText="Senior Leaders"
                  linkThree="/our-company/senior-leaders"
                />
                <div className="heading">Senior Leaders</div>
                <div className="desc">
                  The office-bearers of The Raymond Group formulate a vision and
                  mission and devise a strategy, which has become one of the
                  success stories of the company for decades.
                </div>
              </div>
            </div>
            <div className="col-lg-8 order-lg-1">
              <img
                src={leaderbannerimg}
                alt="Raymond leadership"
                className="leadershipimg"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="leadshipsec2" data-aos="fade-in">
        <div className="my_container">
          <div className="directors_wrapper">
            <div className="directors_flex">{boardOfDirectorsList}</div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default LeadershipInterview;
