let config = {
  api_url: process.env.REACT_APP_BASE_URL,
  image_url: process.env.REACT_APP_IMAGE_URL,
  insta_api: "https://graph.instagram.com/me/media",
  insta_token: process.env.REACT_APP_INSTA_TOKEN,
  image_path: {
    product: "product/",
    blog: "blog/",
    brand: "brand/",
  },
};

export default config;
