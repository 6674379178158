import React, { useEffect, useState } from "react";
import "../../common-styles/GovernanceDirectors.scss";

//components
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import BoardOfDirectorBox from "../../../components/BoardOfDirectorBox/BoardOfDirectorBox";

//images
import directorimg1 from "../../../images/our-company/governance/directorimg1.png";
import directorimg2 from "../../../images/our-company/governance/directorimg2.png";
import { useQuery } from "react-query";
import { request } from "../../../utils/axios-utils";
import Loader from "../../../components/Loader/Loader";
import { getLastSegment, mediaImageUrl } from "../../../helpers/utils";
import useGetDirectorsData from "../../../hooks/useGetDirectorsData";
import { homepageurl } from "../../../helpers/constant-words";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";

const boradOfDirectors = [
  {
    directorImg: directorimg2,
    directorName: "Mrs. Nawaz Gautam Singhania",
    directorDesignation: "Non-Executive Director",
    linkTo: homepageurl,
  },
  {
    directorImg: directorimg1,
    directorName: "Mrs. Nawaz Gautam Singhania",
    directorDesignation: "Non-Executive Director",
    linkTo: homepageurl,
  },
  {
    directorImg: directorimg2,
    directorName: "Mrs. Nawaz Gautam Singhania",
    directorDesignation: "Non-Executive Director",
    linkTo: homepageurl,
  },
  {
    directorImg: directorimg1,
    directorName: "Mrs. Nawaz Gautam Singhania",
    directorDesignation: "Non-Executive Director",
    linkTo: homepageurl,
  },
];

const MediaReportsDirectors = () => {
  const { isLoading, isFetching, directorsData } = useGetDirectorsData(
    "/media/",
    "media-directors"
  );

  const boardOfDirectorsList =
    directorsData?.templateData.length > 0 ? (
      directorsData?.templateData.map(
        ({ name, slug, image, designation }, i) => (
          <BoardOfDirectorBox
            key={i}
            directorImg={mediaImageUrl + image}
            directorName={name}
            directorDesignation={designation}
            linkTo={slug}
          />
        )
      )
    ) : (
      <h3 className="err_message">No Directors data found</h3>
    );

  if (isLoading || isFetching) {
    return <Loader />;
  }

  return (
    <main>
    <MetaDecorator metaTitle="Welcome to Raymond | Media" />
      {directorsData && (
        <section className="directorssec1" data-aos="fade-in">
          <div className="my_container">
            <div className="text_container">
              <BreadCrumb
                linkTwoText="newsroom"
                linkTwoDisabled
                linkThreeText="news and media"
                linkThree="/newsroom/news-and-media"
                linkFourText={directorsData.name}
                linkFourDisabled
              />
              <h1 className="directors_heading">{directorsData.title}</h1>
            </div>
            <div className="directors_wrapper">
              <div className="directors_flex">{boardOfDirectorsList}</div>
            </div>
          </div>
        </section>
      )}
    </main>
  );
};
export default MediaReportsDirectors;
