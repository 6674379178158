import React from "react";
import RightArrowButton from "../../components/RightArrowButton/RightArrowButton";

const BoardOfDirectorBox = ({
  directorImg,
  directorName,
  directorDesignation,
  linkTo,
  showKnowMore = true,
}) => {
  return (
    <>
      <div className="director_box">
        <div className="director_img_wrapper">
          <img
            src={directorImg}
            alt="Board of director"
            className="director_img"
            width={509}
            height={450}
            loading="lazy"
          />
        </div>
        <h4 className="director_name">{directorName}</h4>
        <span className="director_designation">{directorDesignation}</span>
        {showKnowMore && (
          <div className="btn_wrapper">
            <RightArrowButton ctaText="know more" linkTo={linkTo} />
          </div>
        )}
      </div>
    </>
  );
};

export default BoardOfDirectorBox;
