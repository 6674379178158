import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { request } from "../../utils/axios-utils";

import "../common-styles/Governance.scss";
import { useParams } from "react-router-dom";

//components
import RightArrowButton from "../../components/RightArrowButton/RightArrowButton";
import InnerPageBanner from "../../components/InnerPageBanner/InnerPageBanner";

//images
import { searchIcon } from "../../images";
import { ReactComponent as NewsRedLogo } from "../../images/our-company/governance/news-red-logo.svg";
import { ReactComponent as NewsWhiteLogo } from "../../images/our-company/governance/news-white-logo.svg";
import bannerimg from "../../images/our-company/about-us/bannerimg.png";
import Loader from "../../components/Loader/Loader";
import { convertSlugtoString, investorImageUrl } from "../../helpers/utils";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const fetchListing = (subCategorySlug, searchParam, offsetVal) => {
  let url = `/investors/${subCategorySlug}?search=${searchParam.trim()}`;

  if (offsetVal) {
    url += `&offset=${offsetVal}`;
  }

  return request({ url: url, method: "get" });
};

const InvestorsListing = () => {
  const { categorySlug, subCategorySlug } = useParams();
 
  const [listingArr, setListingArr] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [offsetVal, setOffsetVal] = useState(0);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);

  const { isLoading, refetch: refetchListing } = useQuery(
    ["investor-listing", subCategorySlug, searchParam, offsetVal],
    () => fetchListing(subCategorySlug, searchParam, offsetVal),
    {
      enabled: false,
      onSuccess: (data) => {
        if (offsetVal) {
          setListingArr((prevState) => {
            return [...prevState, ...data.data.data];
          });
        } else {
          setListingArr(data.data.data);
        }
        if (data.data.data.length < 12) {
          setShowLoadMore(false);
        } else {
          setShowLoadMore(true);
        }

        setPageLoading(false);
      },
    }
  );
  useEffect(() => {
    if (subCategorySlug) {
      refetchListing();
    }
    // setOffsetVal(0);
  }, [subCategorySlug]);
  useEffect(() => {
    if (offsetVal) {
      refetchListing();
    }
  }, [offsetVal]);

  useEffect(() => {
    // if (searchParam) {
    refetchListing();
    // }
  }, [searchParam]);
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    refetchListing();
  };
  const handleLoadMoreClick = () => {
    setOffsetVal((prevState) => {
      return prevState + 12;
    });
  };
  const govList =
    listingArr.length > 0 ? (
      listingArr.map(({ name, template, slug, url }, i) => (
        <li className="col-lg-3 col-md-6 gov_listing_item" key={i}>
          <div className="news_logos_wrapper">
            <NewsRedLogo className="newslogo newslogored" />
            <NewsWhiteLogo className="newslogo newslogowhite" />
          </div>

          <h4 className="gov_listing_topic_name">{name}</h4>
          <div className="btn_wrapper">
            {template !== "external" ? (
              <RightArrowButton ctaText="read more" linkTo={slug} />
            ) : (
              <RightArrowButton
                ctaText="read more"
                anchorCta
                anchorLink={url}
              />
            )}
          </div>
        </li>
      ))
    ) : (
      <h3 className="err_message">No data Found</h3>
    );
  if (pageLoading) {
    return <Loader />;
  }

  return (
    <main>
      <MetaDecorator metaTitle="Welcome to Raymond | Investor" />
      <section className="govsec1" data-aos="fade-in">
        <InnerPageBanner
          bannerHeading={listingArr[0]?.investorCategory?.title}
          bannerDesc={listingArr[0]?.investorCategory?.description}
          bannerImg={investorImageUrl + listingArr[0]?.investorCategory?.image}
          linkTwoText="investors"
          linkTwoDisabled
          linkThreeText={convertSlugtoString(categorySlug)}
          linkThreeDisabled
          linkFourText={convertSlugtoString(subCategorySlug)}
          linkFourDisabled
          hideImg
        />
      </section>
      <section className="govsec2">
        <div className="my_container">
          <form className="search_form_flex" onSubmit={handleSearchSubmit}>
            <input
              type="text"
              className="search_text"
              role="search"
              placeholder="Type Your Words Here"
              onChange={(e) => {
                setSearchParam(e.target.value);
                setOffsetVal(0);
              }}
            />
            <button type="submit" className="search_btn">
              <img src={searchIcon} alt="Search icon" className="search_icon" />
            </button>
          </form>
        </div>
      </section>

      <section className="govsec3">
        <div className="my_container">
          <ul className="row custom-li">{govList}</ul>
        </div>
      </section>
      {showLoadMore && (
        <div className="load_more_wrapper">
          <span className="load_more_text" onClick={handleLoadMoreClick}>
            {isLoading ? "Loading..." : "Load more"}
          </span>
        </div>
      )}
    </main>
  );
};

export default InvestorsListing;
