export const Data = [
  {
    question:
      "I have purchased Equity Shares of Raymond Limited in the Physical Share Certificate form. How do I register the same in my name?",
    answer:
      "After executing the Share Transfer Deed (available with Stock Exchange/ Share Brokers) in prescribed Form 7-B and affixing stamp duty (currently 0.25% of total consideration viz. the market value of the shares as traded on the date of execution of transfer) thereon, please forward the same along with the share certificate(s) stated therein to the Share Department of the Company at the following address:<ul class='custom-li'><br /><li>Raymond Limited <li>Plot No.156/H. No. 2, Village Zadgaon, </li><li>Ratnagiri, </li><li>Maharashtra – 415 612</li><li>Timings (Monday to Friday):</li><li>10.00 a.m. to 01.00 p.m.</li><li>02.00 p.m. to 06.00 p.m.</li></ul>",
  },
  {
    question: "Where Should I Self-Ship The Returns?",
    answer:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata",
  },
  {
    question: "Packaging",
    answer:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata",
  },
  {
    question: "How Do I Cancel The Order, I Have Placed?",
    answer:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata",
  },
  {
    question:
      "How Will I Detect Fraudulent Emails/Calls Seeking Sensitive Personal And Confidential Information?",
    answer:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata",
  },
];
