import React, { useState, useEffect, useRef } from "react";
import { request } from "../../utils/axios-utils";
import { useQuery } from "react-query";
import { useParams, useLocation } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import RightArrowButton from "../../components/RightArrowButton/RightArrowButton";
import parse from "html-react-parser";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";

import {
  linkedingrey,
  instagrey,
  fbgrey,
  youtubegrey,
  pinterestgrey,
  twiitergrey,
} from "../../images";
import "./OurBrand.scss";
import { brandImageUrl, convertSlugtoString } from "../../helpers/utils";
import Loader from "../../components/Loader/Loader";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const OurBrand = () => {
  const location = useLocation();
  const boxId = location.state?.boxId;
  const { width } = useWindowDimensions();
  const { brandCategory } = useParams();
  const [bannerDetails, setBannerDetails] = useState(null);
  const [brandDataArr, setBrandDataArr] = useState([]);
  const imgRef = useRef();
  const {
    isLoading,
    refetch: refetchBrandDataData,
    isFetching,
  } = useQuery(
    "brand-inner",
    async () => {
      return request({
        url: `/brands/categories/${brandCategory}`,
        method: "get",
      });
    },
    {
      enabled: false,
      onSuccess: (data) => {
        const brandDataObj = data.data.data;
        if (brandDataObj) {
          setBannerDetails(brandDataObj);
          if (brandDataObj.brands && brandDataObj.brands.length > 0) {
            setBrandDataArr(brandDataObj.brands);
          }
        }
      },
    }
  );
  useEffect(() => {
    if (brandCategory) {
      refetchBrandDataData();
    }
  }, [brandCategory]);
  // useEffect(() => {
  //   if (bannerDetails) {
  //    
  //   }
  // }, [bannerDetails]);

  // useEffect(() => {
  // if (boxId) {
  //   const domBoxId = document.getElementById(boxId);
  //   if (boxId === domBoxId?.id) {
  //     applyScroll(boxId, -50);
  //   }
  // }
  // }, [brandDataArr]);
  const brandList =
    brandDataArr.length > 0
      ? brandDataArr.map(
          (
            {
              title,
              description,
              image,
              website,
              facebook,
              instagram,
              linkedin,
              pintrest,
              twitter,
              youtube,
              _id,
            },
            i
          ) => (
            <div className="col-lg-6 flex_box" key={i} id={_id}>
              {width <= 834 ? <div className="mobheading">{title}</div> : null}
              <div className="zoomout">
                <img
                  src={brandImageUrl + image}
                  alt="raymond Brand img"
                  className="mainimg"
                  loading="lazy"
                  width={1544}
                  height={1074}
                />
              </div>
              <div className="text_container">
                {width > 834 ? <div className="heading">{title}</div> : null}
                <div className="desc">{parse(description)}</div>
              </div>
              <div className="cta_social">
                {website && (
                  <div
                    className={`cta_wrapper ${
                      website &&
                      (linkedin ||
                        instagram ||
                        facebook ||
                        pintrest ||
                        twitter ||
                        youtube)
                        ? "active"
                        : ""
                    }`}
                  >
                    <RightArrowButton
                      ctaText="View Website"
                      anchorCta
                      anchorLink={website}
                    />
                  </div>
                )}
                <div className="social_box">
                  {linkedin && (
                    <a href={linkedin} target="_blank" rel="noreferrer">
                      <img
                        className="icon"
                        src={linkedingrey}
                        alt="social"
                        loading="lazy"
                      />
                    </a>
                  )}
                  {instagram && (
                    <a href={instagram} target="_blank" rel="noreferrer">
                      <img
                        className="icon"
                        src={instagrey}
                        alt="social"
                        loading="lazy"
                      />
                    </a>
                  )}
                  {facebook && (
                    <a href={facebook} target="_blank" rel="noreferrer">
                      <img
                        className="icon"
                        src={fbgrey}
                        alt="social"
                        loading="lazy"
                      />
                    </a>
                  )}
                  {pintrest && (
                    <a href={pintrest} target="_blank" rel="noreferrer">
                      <img
                        className="icon"
                        src={twiitergrey}
                        alt="social"
                        loading="lazy"
                      />
                    </a>
                  )}
                  {twitter && (
                    <a href={twitter} target="_blank" rel="noreferrer">
                      <img
                        className="icon"
                        src={youtubegrey}
                        alt="social"
                        loading="lazy"
                      />
                    </a>
                  )}
                  {youtube && (
                    <a href={youtube} target="_blank" rel="noreferrer">
                      <img
                        className="icon"
                        src={pinterestgrey}
                        alt="social"
                        loading="lazy"
                      />
                    </a>
                  )}
                </div>
              </div>
            </div>
          )
        )
      : null;

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  if (isLoading || isFetching) {
    return <Loader />;
  }
  return (
    <>
    <MetaDecorator metaTitle="Welcome to Raymond | Our Brand" />
      <section className="Brand_sec1" data-aos="fade-in">
        {/* bannerDetails use this directly */}
        <div className="my_container">
          {/* {bannerList} */}
          {bannerDetails && (
            <div className="row">
              <div className="col-lg-8">
                {width <= 834 ? (
                  <div className="text_container">
                    <BreadCrumb
                      linkTwoText="our businesses"
                      linkTwoDisabled
                      linkThreeText={convertSlugtoString(bannerDetails.slug)}
                      linkThreeDisabled
                    />
                    <div className="heading">{bannerDetails.title}</div>
                    {width > 600 ? (
                      <div className="desc">
                        {parse(bannerDetails.description)}
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <img
                  src={brandImageUrl + bannerDetails.image}
                  alt="raymond Brand img"
                  className="mainimg"
                  width={1038}
                  height={659}
                  ref={imgRef}
                />
                {width <= 600 ? (
                  <div className="desc">{parse(bannerDetails.description)}</div>
                ) : null}
              </div>
              <div className="col-lg-4 flex_box">
                {width > 834 ? (
                  <div className="text_container">
                    {/* <div className="breadcrumb">breadcrumb</div> */}
                    <BreadCrumb
                      linkTwoText="our businesses"
                      linkTwoDisabled
                      linkThreeText={convertSlugtoString(bannerDetails.slug)}
                      linkThreeDisabled
                    />
                    <div className="heading">{bannerDetails.title}</div>
                    <div className="desc">
                      {parse(bannerDetails.description)}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </section>

      <section className="Brand_sec2" data-aos="fade-in">
        <div className="my_container">
          <div className="row">{brandList}</div>
        </div>
      </section>
    </>
  );
};

export default OurBrand;
